import React from "react";
import Card1 from "../../common/card1/Card1";
import { useNavigate } from "react-router-dom";
import Chef from "../../common/assest/Chef.png";
import TicketLogo from "../../common/assest/ticketManagementLogo.png";
import ContentLogo from "../../common/assest/contentManagementLogo.png";
import ReportLogo from "../../common/assest/reportLogo.png";
import SubscriptionLogo from "../../common/assest/subscriptionLogo.png";
import "./GuestsSection.css";
import Guest from '../../common/assest/guest.png'
const GuestsSection = () => {
  const navigate = useNavigate();

  const style = {
    maxWidth: "300px",
  };
  return (
    <div>
      <div className="chef-section-card-container">
        <Card1
          text="Guest Details"
          img={Guest}
          style={style}
          onClick={() => navigate("/dashboard/guests/all-guests")}
        />
        <Card1
          text="Ticket Management"
          img={TicketLogo}
          style={style}
          onClick={() => navigate("/dashboard/guests/ticket-management")}
        />
        {/* <Card1
          text="Content Management "
          img={ContentLogo}
          style={style}
          onClick={() => navigate("/dashboard/guests/content-management")}
        /> */}
        {/* <Card1
          text="Reports"
          img={ReportLogo}
          style={style}
          onClick={() => navigate("/dashboard/guests/reports")}
        /> */}
        <Card1
          text="Subscription"
          img={SubscriptionLogo}
          style={style}
          onClick={() => navigate("/dashboard/guests/subscription-management")}
        />
      </div>
    </div>
  );
};

export default GuestsSection;
