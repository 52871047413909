import React, { useState } from "react";
import { TableRow, TableCell, Box, Avatar, Menu, MenuItem } from "@mui/material";

import CharMenuIcon from '../assest/charmenuicon.svg'
import { useNavigate } from "react-router-dom";

const ChefRow = ({handleViewMore,row}) => {

const navigate=useNavigate();
 const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate=()=>{
    navigate('/dashboard/chefs/all-chefs/chefs-information')
    handleClose();
  }
 

  return (
    <>
    
        <TableRow >
          <TableCell>
            <Box 
              onClick={()=>navigate('/dashboard/chefs/all-chefs/chefs-information')}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // flexWrap: "wrap",
                gap: "10px",
                cursor:"pointer"
              }}
            >
              <Avatar
                alt={row.chefName}
                src={row.chefImage}
                sx={{ borderRadius: "13px" }}
              />
              <div style={{ 
                 color: "#1877F2",
                 fontFamily: "Rosario",
                 textDecoration: "underline",
                 fontWeight: 500,
                 fontSize: "17px",
                 }}>
                {row.chefName||"-"}
              </div>
            </Box>
          </TableCell>

          <TableCell
        
            sx={{
            
              fontFamily: "Rosario",
           
              fontSize: "17px",
            }}
          >
            <div >{row.email}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row.experience}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row.events}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row.rating}</div>
          </TableCell>
          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row.earnings}</div>
          </TableCell>

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{row.joineddate}</div>
          </TableCell>

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{row.subs}</div>
          </TableCell>


          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
              color:'#009048'
             
            }}
          >
            <div>{row.accountstatus}</div>
          </TableCell>

          <TableCell
            onClick={handleViewMore}
            sx={{
                color: "#1877F2",
                fontFamily: "Rosario",
                textDecoration: "underline",
                fontWeight: 500,
                fontSize: "17px",
                 cursor:'pointer'
            }}
          >
            <div>View More</div>
          </TableCell>

          
          <TableCell
           
            sx={{
                color: "#1877F2",
                fontFamily: "Rosario",
                textDecoration: "underline",
                fontWeight: 500,
                fontSize: "17px",
                cursor:'pointer'
            }}
          >
            <div><img src={CharMenuIcon} onClick={handleClick} /></div>
          </TableCell>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
                  borderRadius: '25px', 
                  bgcolor: 'white',
                  boxShadow:"none",
                  border: "1px solid #B0B0B0"
                },
              }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
            <MenuItem onClick={handleNavigate} sx={{fontFamily:'Rosario'}}>View User Account</MenuItem>
            <MenuItem onClick={handleClose} sx={{fontFamily:'Rosario'}}>Suspend User Account</MenuItem>
            <MenuItem onClick={handleClose} sx={{fontFamily:'Rosario',color:'#EA1D26',fontWeight:'400'}}>Delete User Account</MenuItem>
        </Menu>


        </TableRow>

        
    
    </>
  );
};

export default ChefRow;
