import { Card } from '@mui/material'
import React  from 'react'
import './CustomCard1.css'
const CustomCard1 = ({ children,style }) => {
    return (
        <div className='card-container-accmanagement' style={style}>
            <Card
                sx={{
                    // width: { xs: '90%', sm: '800px' },
                    margin: { xs: 'auto',md:'auto' },
                    // height: '525px',
                    height: 'auto',
                    borderRadius: '31px',
                    backgroundColor: '#FFF',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding:"40px",
                    ...style 
                   
                    // margin: 'auto', 
                    // top: '20%',
                }}
            >
                {children}
            </Card>
        </div>
    )
}

export default CustomCard1