import React from 'react'
import Card2 from '../../common/card2/Card2'
import { Link, useNavigate } from 'react-router-dom'


const GuestsContentManagement = () => {
  const navigate=useNavigate();


const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/guests" className="All-color-breakcurm">
      Guest
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Ticket Management</span>,
  ];

  return (
    <div>
 <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>
      
  <div className="chef-content-card-wrapper">
      <Card2 onClick={()=>navigate("/dashboard/guests/content-management/faq")}>
          <div className="content-management-text">
          FAQ
          </div>
        </Card2>
        <Card2 onClick={()=>navigate("/dashboard/guests/content-management/mission")}>
          <div className="content-management-text">
            Mission
          </div>
        </Card2>
        </div>
    </div>
  )
}

export default GuestsContentManagement