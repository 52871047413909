import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Card1 from "../../common/card1/Card1";
import TableCard from "../../common/tableCard/TableCard";
import TableComp from "../../common/tablecomp/TableComp";
import userimg from "../../common/assest/usrimg.png";
import CustomModal from "../../common/modal/CustomModal";
import ClearIcon from "@mui/icons-material/Clear";
import SearchWithFilter from "../../common/searchbar/SearchWithFilter";
import GuestsRow from "../../common/tablerow/GuestsRow";
import './Guests.css'
import { useSelector } from "react-redux";
import { getGuestsCountApi, getGuestsCountInMonthApi } from "../../api";
import Loader from "../../common/loader/Loader";

const GuestsDetails = () => {

  const token = useSelector((state) => state?.user?.user?.token);
  const [open, setOpen] = useState(false);
  const [open1,setOpen1]=useState(false) 
  const [loading,setLoading]=useState(false);
  const [totalCount,setTotalCount]=useState(0);
  const [countInMonth,setCountInMonth]=useState(0);


  

  const handleCounts = async () => {
    try {
      setLoading(true)
      const [responseCount, responseCountInMonth] = await Promise.all([
        getGuestsCountApi({ token }),
        getGuestsCountInMonthApi({ token })
      ]);
      setLoading(false)
      setTotalCount(responseCount || 0); 
      setCountInMonth(responseCountInMonth|| 0);
    } catch (error) {
      setLoading(false)
      console.log("Error:", error);
    }
  };
  
  useEffect(() => {
    handleCounts();
  }, []);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setOpen1(false)
  };

  const openModal = (item) => {
    setOpen(true);
  
  };
  const openModal1 = (item) => {
    
    setOpen1(true);
  
  };
  

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/guests" className="All-color-breakcurm">
      Guests
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Guest’s Details</span>,
  ];

  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };

  const titles = [
    { title: "Guests Name" },
    { title: "Email" },
    { title: "Address" },
    { title: "Attended Events" },
    { title: "Joined Date" },
    { title: "Subscription " },
    { title: "Account Status " },
  ];
  const rows = [
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      email: "joe@gmail.com",
      adress:"13th Street. 47 W 13th St, New York, NY 10011, USA. ",
      experience: "2",
      events: "150",
      joineddate: "05/16/2022",
      subs: "free",
      accountstatus: "Active",
    },
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      email: "joe@gmail.com",
      adress:"13th Street. 47 W 13th St, New York, NY 10011, USA. ",
      experience: "2",
      events: "150",
      joineddate: "05/16/2022",
      subs: "free",
      accountstatus: "Active",
    },
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      email: "joe@gmail.com",
      adress:"13th Street. 47 W 13th St, New York, NY 10011, USA. ",
      experience: "2",
      events: "150",
      joineddate: "05/16/2022",
      subs: "free",
      accountstatus: "Active",
    },
  ];

  const styles={
    height:"200",
  }

  return (

  <>
   {loading ? (
      
      <Loader/>
    ):(
        
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="chef-details-card-container">
        <Card1 value={totalCount} text="Total Guests" style={style} />
        <Card1 value={countInMonth} text="Total Guests This Month" style={style} />
        <Card1
          value="67890"
          text="Active Guests Currently on App"
          style={style}
        />
      </div>

     <div className="guests-table">
     <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
            gap:'10px'
          }}
        >
          <div>Guest’s Details</div>
          <div>
            <SearchWithFilter />
          </div>
        </div>

        <TableComp title={titles} emptyEColumnCount={2}>
          {rows.map((item) => (
            <GuestsRow handleViewMore={() => openModal(item)} handleEvents={()=>openModal1(item)} row={item} />
          ))}
        </TableComp>
      </TableCard>
     </div>

      <CustomModal open={open} handleClose={handleClose}>
        <div
          onClick={handleClose}
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            color: "#6E6E6E",
            cursor: "pointer",
          }}
        >
          <ClearIcon />
        </div>

        <div className="img-mail-name-container">
            <div><img src={userimg}/></div>
            <div className="name-email-container">
                <div  style={{fontFamily:'Rosario'}}>Jonathan Williams</div>
                <div style={{color:'#868686',fontFamily:'Rosario'}}>joe@gmail.com</div>
            </div>
          </div>

        <div className="modal-txt">Birthdate:<span> 13th November, 1998</span> </div>
        <div className="modal-txt">Category:<span> Italian, Indian </span> </div>
        <div className="modal-txt">Beverage Preference: <span>  Non Alcoholic </span> </div>
        <div className="modal-txt">Dining Style:<span> Outdoors </span> </div>
      </CustomModal>


      <CustomModal open={open1} handleClose={handleClose}>
        <div
          onClick={handleClose}
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            color: "#6E6E6E",
            cursor: "pointer",
          }}
        >
          <ClearIcon />
        </div>
        <div className="modal-txt" style={{fontSize:'24px'}}>Event 1 Name<p > 10130 Perimeter Parkway Suite, Charloette, North Carolina, 1087 </p> </div>
        <div className="modal-txt" style={{fontSize:'24px'}}>Event 1 Name<p>10130 Perimeter Parkway Suite, Charloette, North Carolina, 1087</p> </div>
      </CustomModal>



      <div className="txt-sample">
        Copyright © 2024 Chef’s Table All rights reserved.
      </div>
    </div>
    )}
  </>
  );
};

export default GuestsDetails;
