import React from "react";

import Card1 from "../../common/card1/Card1";
import SearchBar from "../../common/searchbar/SearchBar";
import TableCard from "../../common/tableCard/TableCard";
import TableComp from "../../common/tablecomp/TableComp";
import ChefRow from "../../common/tablerow/ChefRow";

import { Link } from "react-router-dom";
import EarningsRow from "../../common/tablerow/EarningsRow";
import EventsRows from "../../common/tablerow/EventsRow";
import GuestsEventRow from "../../common/tablerow/GuestsEventRow";

const GuestsEvents = () => {
  
    const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };
  const titles = [
    { title: "Sr No" },
    { title: "Event Name" },
    { title: "Address" },
    { title: "Date" },
    { title: "Ratings" },
    { title: "Payment" },
    { title: "Payment Status" },

  ];
  const rows = [
    {
      id: 1,
      email: "ABC",
      experience: " 13th Street. 47 W 13th St, New York, NY 10011, USA. ",
      events: "4.4",
      earnings: "$5677",
      joineddate: "05/16/2022",
      paymentStatus:"Done"
    },
    {
      id: 2,
      email: "ABC",
      experience: " 13th Street. 47 W 13th St, New York, NY 10011, USA. ",
      events: "4.4",
      earnings: "$5677",
      joineddate: "05/16/2022",
       paymentStatus:"Pending"
    },
  ];

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/guests" className="All-color-breakcurm">
      Guest’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/guests/all-guests"
      className="All-color-breakcurm"
    >
      Guest’s Details
    </Link>,
    <Link
      key="home"
      to="/dashboard/guests/all-guests/guests-information"
      className="All-color-breakcurm"
    >
      Guest’s Name
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Events</span>,
  ];


  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="chef-details-card-container">
        <Card1 v value="50" text="Total Events  >" style={style} />
      </div>

      <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
             gap:'10px'
          }}
        >
          <div>Events Details</div>
          <div>
            <SearchBar />
          </div>
        </div>

        <TableComp title={titles}>
          {rows.map((item) => (
            <GuestsEventRow row={item} />
          ))}
        </TableComp>
      </TableCard>
    </div>
  );
};

export default GuestsEvents;
