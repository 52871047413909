import React, { useEffect, useState } from 'react';
import { IconButton, Badge, Button, Menu, MenuItem, MenuList } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NavBarImg from '../assest/navbarImg.png'
import ProfileIcon from '../assest/logoutIcon.svg'
import './Main.css';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/userSlice';
import { toast } from 'react-toastify';


const Navbar = ({ toggleSidebar }) => {

  const userData = useSelector((state) => state?.user?.user);
  const dispatch=useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [showLogoutDropdown, setShowLogoutDropdown] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [allowView, setAllowView] = useState(false);
  const [user, setUser] = useState();
  const navigate=useNavigate();

  const toggleLogoutDropdown = () => {
    setShowLogoutDropdown(!showLogoutDropdown);
  };
  const toggleNotificationDropdown = () => {
    setShowNotification(!showNotification);
  };

  const closeNotification=()=>{
    setShowNotification(false)
  }

  const logOut = () => {
    const isConfirmed = window.confirm("Are you sure you want to logout?");
    if (!isConfirmed) {
      return;
    } else {
      dispatch(logout());
      toast.success("Admin Logout");
    }
  };



const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleClose = () => {
  setAnchorEl(null);
};


  return (
    <nav>
      <img src={NavBarImg} className="c" alt="top banner" />
      <div className="header">
        <div style={{ width: '55%', display: 'flex', alignContent: 'center', alignItems: 'center' }}>
          <IconButton onClick={toggleSidebar} className="hamburger-menu">
            <MenuOutlinedIcon sx={{ color: '#FFF',fontSize:'30px' }} />
          </IconButton>
          <div className="welcome-container">
            <div className="welcome-text-dashboard">Welcome {userData?.full_name||"Admin"}</div>
            {/* <p className="home-user-account">Home-User-Account</p> */}
          </div>
        </div>
        <div className="icons" style={{ marginRight: '20px' }}>
          <IconButton onClick={toggleNotificationDropdown}>
            <div style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#EA1D26',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative'
            }}>
              <Badge
                badgeContent=" "
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiBadge-dot': {
                    backgroundColor: '#FFE20A',
                  }
                }}
              >
                <NotificationsIcon style={{ color: 'white', fontSize: '25px' }} />
              </Badge>
            </div>
          </IconButton>
          {showNotification && (
          <div
           className='shownotification'
            style={{
              borderRadius: '20px 20px 20px 20px',
              background: '#FFF',
              boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.20)',
              width: '320px',
              minHeight:'200px',
              // overflowY:'scroll',
              position: 'absolute',
              bottom: '0px', 
              top:'69px',
              right: '120px',  
              display: 'flex',
              // alignItems: 'center',
              justifyContent: 'space-between',
              color:'#9A031E',
              fontWeight:'600',
              cursor:'pointer'
            }}
            onClick={closeNotification}
          >
            <div style={{display:'flex',justifyContent:'space-between',alignContent:'center',width:'100%',padding:'15px',marginTop:'10px'}}>
                <div>Notifications</div>
                <div style={{color:'black',backgroundColor:"#EBEBEB",height:"22px",width:'25px',borderRadius:'50%',textAlign:'center'}} onClick={closeNotification}><CloseIcon sx={{fontSize:'15px',textAlign:'center'}}/></div>
              </div>
          </div>
        )}







          <IconButton  >
            <img src={ProfileIcon} style={{ height: '40px' }} alt="Profile"   onClick={handleClick} />
          </IconButton>
          {/* {showLogoutDropdown && (
          <div
            style={{
              
            }}
            onClick={logOut}
          >
           Logout
          </div>
        )} */}
         <div >
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}

            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
              borderRadius: '16px 0px 16px 16px',
              background: '#FFF',
              boxShadow: '2px 4px 16px 0px rgba(0, 0, 0, 0.20)',
              width: '100px',
              height: '38px',
              
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color:'#9A031E',
              fontWeight:'600',
              cursor:'pointer'
                },
              }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
            <div onClick={logOut} style={{fontFamily:'Rosario'}}>Logout</div>
        </Menu>
        </div>
        
        </div>
      </div>
    </nav>
  );
};

export default Navbar;