import React from "react";
import { TableRow, TableCell, Box, Avatar } from "@mui/material";
import userimg from "../../common/assest/usrimg.png";
import CharMenuIcon from "../assest/charmenuicon.svg";
import { useNavigate } from "react-router-dom";
import EditIcon from "../assest/editicon.svg";
import DeletIcon from "../assest/deleteicon.svg";

const NotificationRow = () => {
  const navigate = useNavigate();

  const rows = [
    {
      title: "Jonathan Williams",
      category: "Category name",
      desc: "Lorem ipsom is simple dummy text...",
      pushdate: "05/16/2022",
      notificationsendto: "All User",
    },
    {
      title: "Jonathan Williams",
      category: "Category name",
      desc: "Lorem ipsom is simple dummy text...",
      pushdate: "05/16/2022",
      notificationsendto: "All User",
    },
    {
      title: "Jonathan Williams",
      category: "Category name",
      desc: "Lorem ipsom is simple dummy text...",
      pushdate: "05/16/2022",
      notificationsendto: "All User",
    },
  ];

  return (
    <>
      {rows.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <div
                style={{
                  fontFamily: "Rosario",

                  fontWeight: 500,
                  fontSize: "17px",
                }}
              >
                {row.title}
              </div>
            </Box>
          </TableCell>

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontSize: "16px",
            }}
          >
            <div>{row.category}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "16px" }}
          >
            <div>{row.desc}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "16px" }}
          >
            <div>{row.pushdate}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "16px" }}
          >
            <div>{row.notificationsendto}</div>
          </TableCell>

          <TableCell
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              cursor: "pointer",
              flexWrap:'wrap',
              gap:'10px'
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#595555",
                height: "43px",
                width: "43px",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <img src={EditIcon} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#EA1D26",
                height: "43px",
                width: "43px",
                alignItems: "center",
                borderRadius: "10px",
              }}
            >
              <img src={DeletIcon} />
            </div>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default NotificationRow;
