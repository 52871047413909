import React, { useState, useEffect } from "react";
import style from "./ImageUploader.module.css";
import uploadIcon from "../assest/upload.svg";

const ImgUploader = ({ initialProfile, onProfilePictureChange }) => {


  const [selectedImage, setSelectedImage] = useState(initialProfile);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);

      // Pass the file to the parent component
      onProfilePictureChange(file);
    }
  };

  useEffect(() => {
    setSelectedImage(initialProfile);
  }, [initialProfile]);

  return (
    <div className={style.imageUploaderContainer}>
      <div className={style.imageCircle}>
        {selectedImage ? (
          <img src={selectedImage} alt="Selected" className={style.previewImage} />
        ) : (
          <div className={style.placeholderIcon}>
            {/* <img src={uploadIcon} alt="Upload" /> */}
            
          </div>
        )}
      </div>

      <div className={style.uploadButton} style={{ cursor: "pointer !important" }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className={style.fileInput}
          style={{ cursor: "pointer !important" }}
        />
        <img src={uploadIcon} alt="Upload" />
      </div>
    </div>
  );
};

export default ImgUploader;
