import React, { useState } from "react";
import { TableRow, TableCell, Box, Avatar, Menu, MenuItem } from "@mui/material";

import CharMenuIcon from '../assest/charmenuicon.svg'
import { useNavigate } from "react-router-dom";

const SubscriptionRow = ({handleViewMore,row}) => {

const navigate=useNavigate();
 const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate=()=>{
    navigate('/dashboard/chefs/all-chefs/chefs-information')
    handleClose();
  }
 

  return (
    <>
    
        <TableRow >
          <TableCell>
            <Box 
             
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // flexWrap: "wrap",
                gap: "10px",
               
              }}
            >
              <Avatar
                alt={row.chefName}
                src={row.chefImage}
                sx={{ borderRadius: "13px" }}
              />
              <div style={{ 
                
                 fontFamily: "Rosario",
                 
                 fontWeight: 500,
                 fontSize: "17px",
                 }}>
                {row.chefName||"-"}
              </div>
            </Box>
          </TableCell>

         

         
         
          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{row.joineddate}</div>
          </TableCell>


       
        

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
             
            }}
          >
            <div>{row.subs}</div>
          </TableCell>
          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row.earnings}</div>
          </TableCell>


          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" ,color:'#888',cursor:'pointer'}}
          >
            <div>Download invoice</div>
          </TableCell>
       
          
        

        </TableRow>

        
    
    </>
  );
};

export default SubscriptionRow;
