import React from "react";
import { TableRow, TableCell, Box, Avatar } from "@mui/material";
import userimg from "../../common/assest/usrimg.png";
import { useNavigate } from "react-router-dom";

const CommissionRow = () => {

    const navigate=useNavigate();

  const rows = [
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      eventName: "Wedding Event",
      eventDate: "2024-10-14",
      totalGuests: "150",
      totalPayment: "$5000",
      commissionReceived: "$500",
    },
    {
      chefImage: userimg,
      chefName: "Sarah Johnson",
      eventName: "Corporate Dinner",
      eventDate: "2024-11-02",
      totalGuests: "100",
      totalPayment: "$4500",
      commissionReceived: "$450",
    },
    {
      chefImage: userimg,
      chefName: "Michael Brown",
      eventName: "Birthday Party",
      eventDate: "2024-12-01",
      totalGuests: "75",
      totalPayment: "$3500",
      commissionReceived: "$350",
    },
  ];

  return (
    <>
      {rows.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <Avatar
                alt={row.chefName}
                src={row.chefImage}
                sx={{ borderRadius: "13px" }}
              />
              <div style={{ fontFamily: "Rosario", fontSize: "17px" }}>
                {row.chefName}
              </div>
            </Box>
          </TableCell>

          <TableCell
          onClick={()=>navigate('/dashboard/commision-management/events-details/:id')}
            sx={{
              color: "#1877F2",
              fontFamily: "Rosario",
              textDecoration: "underline",
              fontWeight: 500,
              fontSize: "17px",
            }}
          >
            <div style={{ cursor: "pointer" }}>{row.eventName}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row.eventDate}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row.totalGuests}</div>
          </TableCell>

          <TableCell
            sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
          >
            <div>{row.totalPayment}</div>
          </TableCell>

          <TableCell
            sx={{
              fontFamily: "Rosario",
              fontWeight: 500,
              fontSize: "17px",
              color: "#EA1D26",
            }}
          >
            <div>{row.commissionReceived}</div>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default CommissionRow;
