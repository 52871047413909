
import { Button } from '@mui/material';
import React, { useState } from 'react'
import AllTicket from './AllTicket';
import UnResolved from './UnResolved';
import Resolved from './Resolved';

const TicketBtn = () => {

    const [activeTab, setActiveTab] = useState(0);

    const handleClick = (index) => {
        setActiveTab(index);
    };
    return (
        <div>
            <div className='five-btn-container'>
                <Button
                    onClick={() => handleClick(0)}
                    className="responsive-btn"
                    sx={{
                        width: '198px',
                        height: '65px',
                        borderRadius: '100px',
                        textTransform: 'none',
                        border: '1px solid #DCDCDC',
                        backgroundColor: activeTab === 0 ? '#EA1D26' : '#FFF',
                        color: activeTab === 0 ? '#FFF' : '#C6C6C6',
                        '&:hover': { backgroundColor: '#EA1D26' },
                    }}
                >
                    All Tickets (100)
                </Button>
                <Button
                    onClick={() => handleClick(1)}
                    className="responsive-btn"
                    sx={{
                        width: '198px',
                        height: '65px',
                        border: '1px solid #DCDCDC',
                        borderRadius: '100px',
                        textTransform: 'none',
                        backgroundColor: activeTab === 1 ? '#EA1D26' : '#FFF',
                        color: activeTab === 1 ? '#FFF' : '#C6C6C6',
                        '&:hover': { backgroundColor: '#EA1D26' },
                    }}
                >
                    Unresolved (2)
                </Button>
                <Button
                    onClick={() => handleClick(2)}
                    className="responsive-btn"
                    sx={{
                        width: '198px',
                        height: '65px',
                        border: '1px solid #DCDCDC',
                        borderRadius: '100px',
                        textTransform: 'none',
                        backgroundColor: activeTab === 2 ? '#EA1D26' : '#FFF',
                        color: activeTab === 2 ? '#FFF' : '#C6C6C6',
                        '&:hover': { backgroundColor: '#EA1D26' },
                    }}
                >
                    Resolved (98)
                </Button>

            </div>


            {activeTab===0 && <AllTicket/>}
            
            {activeTab===1 && <UnResolved/>}
            
            {activeTab===2 && <Resolved/>}

            
        </div>
    )
}

export default TicketBtn