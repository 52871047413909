import React from 'react'
import './CustomBtn1.css'
import { Button } from '@mui/material'
const CustomBtn1 = ({text,onClick,disable,style}) => {
  return (
    <Button
    onClick={onClick}
    disabled={disable}
    sx={{
      
      width: "100%",
      // maxWidth: "380px",
      height: "48px",
      flexShrink: 0,
      borderRadius: "100px",
      background: "#EA1D26",
      color: "#FFF !important",
      textAlign: "center",
      fontFamily: "Rosario",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "13px",
    '@media (max-width: 480px)': {
        height: '45px', 
        fontSize: '14px', 
        borderRadius: '50px', 
      },
      ...style,
    }}
    
  >
    {text}
  </Button>
  )
}

export default CustomBtn1