import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card1 from "../../common/card1/Card1";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const GuestsPersonalInfo = () => {

  const navigate = useNavigate();
  const [showCreateNotification, setShowCreateNotification] = useState(false);

 const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateNotification = () => {
    setShowCreateNotification(true);
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">Home</Link>,
    <Link key="chefs" to="/dashboard/guests" className="All-color-breakcurm">Guest's</Link>,
    <Link key="chefs-details" to="/dashboard/guests/all-guests" className="All-color-breakcurm">Guest's Details</Link>,
    <span key="chef-name" style={{ fontWeight: "bolder" }}>&nbsp;Guest's Name</span>,
  ];

  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "pointer",
  };

  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="chef-details-card-container" style={{ display: "flex"}}>
        <div style={{gap: "20px",flexWrap: "wrap", display:'flex',width: "50%",flexGrow: 1}} className="chef-personal-card-style">
        <Card1
          value="4.4"
          value1="124 reviews"
          text="Total Reviews  >"
          style={style}
          onClick={() => navigate("/dashboard/guests/all-guests/guests-information/guests-reviews")}
        />
        <Card1
          onClick={() => navigate("/dashboard/guests/all-guests/guests-information/guests-events")}
          value="50"
          text="Total Events  >"
          style={style}
        />
        </div>
        <div >
          <Button
            sx={{ width: "190px", fontFamily: "Rosario",cursor:'pointer'}}
            onClick={handleClick}
            variant="contained"
            className="create-admin-btn"
            endIcon={<ArrowDropDownIcon className="create-admin-icon" />}
          >
            More Options
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
                  borderRadius: '25px', 
                  bgcolor: 'white',
                  boxShadow:"none",
                  border: "1px solid #B0B0B0",
                  padding:'5px',
                  width:"180px"
                },
              }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
            <MenuItem onClick={handleClose} sx={{fontFamily:'Rosario'}}>Resume Account</MenuItem>
            <MenuItem onClick={handleClose} sx={{fontFamily:'Rosario',color:'#EA1D26',fontWeight:'400'}}>Delete Account</MenuItem>
        </Menu>
        </div>
      </div>

      <div style={{ backgroundColor: "#E8E8E8", height: "5px", width: "100%", marginTop: "-40px" }} className="hr-lines"/>

      <div className="personal-info-chefs">
        <Grid container spacing={6} justifyContent="center">
          {/* Left Side: Image Uploader */}
          <Grid item xs={12} sm={12} md={2}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
              <div style={{ width: "140px", height: "140px", borderRadius: "50%", border: "1px solid #EA1D26" }} />
              <div style={{ wordBreak: "break-word", marginTop: "10px" }}>Name</div>
              <p style={{ wordBreak: "break-word" }}>Email</p>
            </div>
          </Grid>

          {/* Right Side: Input Fields */}
          <Grid item xs={12} sm={12} md={10}>
            <div style={{ fontFamily: "Rosario", fontWeight: 700, marginBottom: "20px", marginLeft: "10px" }}>
              Basic Details
            </div>
            <Grid container spacing={2}>
              {/* First Name and Email */}
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">First Name</Typography>
                <input type="text" placeholder="Enter your Name" name="firstName" className="chef-common-input" readOnly />

                <Typography className="acc-management-text" sx={{ marginTop: "10px" }}>Email Id</Typography>
                <input type="email" placeholder="Enter your Email" name="email" className="chef-common-input" readOnly />
              </Grid>

              {/* Last Name and Phone Number */}
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Last Name</Typography>
                <input type="text" placeholder="Enter Last Name" name="lastname" className="chef-common-input" readOnly />

                <Typography className="acc-management-text" sx={{ marginTop: "10px" }}>Birthdate</Typography>
                <input type="number" placeholder="Birthdate" name="number" className="chef-common-input" readOnly />
              </Grid>

              {/* Address */}
              <Grid item xs={12}>
                <Typography className="acc-management-text">Address</Typography>
                <input className="chef-common-input" placeholder="Address" style={{ width: "100%" }} readOnly />
              </Grid>

              

              {/* Birthdate and Specialty Fields Side by Side */}
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Category</Typography>
                <input type="text" placeholder="Category" name="category" className="chef-common-input" readOnly />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Beverage Preference</Typography>
                <input type="text" placeholder="Beverage Preference" name="specialty" className="chef-common-input" readOnly />
              </Grid>

            
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Dining Style</Typography>
                <input type="text" placeholder="Dining Style" name="language" className="chef-common-input" readOnly />
              </Grid>

        
            </Grid>
          </Grid>
        </Grid>

        <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px",marginTop:'30px'}}>
          <Button
            sx={{
              width: "230px",
              fontFamily: "Rosario",
              border: "1px solid #5B5B5B",
              height: "50px",
              borderRadius: "100px",
              color: "#5B5B5B",
            }}
          >
            Suspicious Account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GuestsPersonalInfo;
