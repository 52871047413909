import React from "react";
import { Link } from "react-router-dom";
import CalenderLogo from "../../common/assest/datesvg.svg";
import EventDetailsbtn from "../../common/EventDetailsBtn/EventDetailsbtn";

const GuestsEventInfo = () => {
  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
       Guest’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/guests/all-guests"
      className="All-color-breakcurm"
    >
       Guest’s Details
    </Link>,
    <Link
      key="home"
      to="/dashboard/guests/all-guests/guests-information"
      className="All-color-breakcurm"
    >
       Guest’s Name
    </Link>,
    <Link
      key="home"
      to="/dashboard/guests/all-guests/guests-information/guests-events"
      className="All-color-breakcurm"
    >
       Events
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;ABS Events</span>,
  ];
  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="date-event-details" style={{display:'flex',alignItems:'center',justifyContent:'start',gap:"20px"}}>
        <div>
          <img src={CalenderLogo} alt="calender-logo" />
        </div>
        Event Details: 10/23/2023 | 7:00 pm to 10:00 pm
      </div>

      <div className="chef-events-information-details">
        <EventDetailsbtn/>
      </div>
    </div>
  );
};

export default GuestsEventInfo;
