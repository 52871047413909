
import { TextField, InputAdornment, Menu, MenuItem, MenuList } from '@mui/material'
import SearchItem from '../../common/assest/searchIcon.svg'
import filterIcon from '../../common/assest/filterIcon.svg'
import React from 'react';
const SearchWithFilter = ({style}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

  return (
    <>
      <TextField
          
           placeholder="Search..."
           sx={{
             width: {  sm: 300 }, 
             ...style,
             height: {xs:45,sm:45},
             flexShrink: 0,
             borderRadius: "33px",
             background: "#F6F5F6",
             
             border: "none", 
             "& .MuiOutlinedInput-root": {
               borderRadius: "33px",
               height: "100%",
               paddingRight: "10px",
               outline: "none",
               border: "none", 
             },
             "& .MuiOutlinedInput-notchedOutline": {
               border: "none", 
             },
           }}
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">
                  <img src={SearchItem}
                   sx={{ color: "#000", opacity: "0.35" }}
                 />
               </InputAdornment>
             ),
             endAdornment: (
                <InputAdornment position="end" onClick={handleClick}>
                   <img src={filterIcon}
                    style={{ cursor:"pointer" }}
                  />
                </InputAdornment>
              ),
             style: { height: "100%" },
           }}
         />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            sx={{
                '& .MuiPaper-root': {
                  borderRadius: '25px', 
                  bgcolor: 'white',
                  boxShadow:"none",
                  border: "1px solid #B0B0B0",
                  width:"180px",
                  paddingLeft:'20px'
                },
              }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
            <MenuList sx={{fontFamily:'Rosario',color:'#EA1D26'}}>Subscription</MenuList>
            <MenuItem sx={{fontFamily:'Rosario'}}><input type='checkbox'/>Free</MenuItem>
            <MenuItem sx={{fontFamily:'Rosario'}}><input type='checkbox'/>Premium</MenuItem>
            <MenuList  sx={{fontFamily:'Rosario',color:'#EA1D26'}}>Account Status</MenuList>
            <MenuItem sx={{fontFamily:'Rosario'}}><input type='checkbox'/>Active</MenuItem>
            <MenuItem sx={{fontFamily:'Rosario'}}><input type='checkbox'/>Inactive</MenuItem>
        </Menu>
        </>
  )
}

export default SearchWithFilter