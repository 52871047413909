import React, { useState } from 'react'
import './PushNotification.css';
import { Link } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Button } from '@mui/material';
import CreateNotification from './CreateNotification';
import TableCard from '../../common/tableCard/TableCard';
import SearchBar from '../../common/searchbar/SearchBar';
import NotificationRow from '../../common/tablerow/NotificationRow';
import TableComp from '../../common/tablecomp/TableComp';

const PushNotification = () => {

  const [showCreateNotification, setShowCreateNotification] = useState(false);

  const handleCreateNotification = () => {
    setShowCreateNotification(true);
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/settings" className="All-color-breakcurm">
      Settings
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Push Notification</span>,
  ];

  const styles={
    width:"275px"
  }
  const titles = [
    { title: "Notification Title" },
    { title: "Category" },
    { title: "Description" },
    { title: "Push Date" },
    { title: "Notification send to" },
    { title: "Action" },
  ];
  return (
    <div>


      <>
        <div className="bread-crum">
          {breadcrumbItems.map((item, index) => (
            <div className="all-breakcrum" key={index}>
              {item}
              {index < breadcrumbItems.length - 1 && " > "}
            </div>
          ))}
        </div>
        {!showCreateNotification ? (

          <div className='whole-card-wrapper-notification'>
          <TableCard >
            <div className='push-notification-txt'>All Push Notification</div>

            <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',alignItems:'center',padding:'20px',gap:"10px"}}>

            <div><SearchBar style={styles}/></div>


              <div> <Button
                onClick={handleCreateNotification}
                variant="contained"
                className="create-admin-btn"
                startIcon={<AddCircleOutlineOutlinedIcon className="create-admin-icon" />}
              >
                Create New Notification
              </Button>
              </div>


            </div>

            <TableComp title={titles}>
              <NotificationRow />
          </TableComp>


          </TableCard>

          </div>
        ) : (

          <CreateNotification />
        )}
      </>

    </div>
  );
};

export default PushNotification;
