import { Axios } from "./axios.interceptor";

// ************************************************ login Api*****************************************************
export const loginApi = (user) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.post(
                "/admin/login",
                {
                    email: user.email,
                    password: user.password,

                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};



// forget section 

export const verifyEmailApi = ({email}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/forgot-password",
                {
                    email
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};

export const verifyOTPApi = ({email,otp}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/verify-otp",
                {
                    email,
                    otp
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};


export const changePasswordApi = ({otpData,confirmNewPass}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/reset-password",
                {
                    email:otpData.reference,
                    otp:otpData.otp,
                    new_password:confirmNewPass
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};


// *************************************Graph Data***********************************************************************
export const getGraphData=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/users-enrolled`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}


// ************************************************ Admin Management *****************************************************
export const getAllAdminApi=({token,currentPage})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/get-all?page=${currentPage}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const addAdminApi=({token,admin})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                "/admin/add",
                {   full_name:admin.fullName,
                    email: admin.email,
                    password: admin.password,

                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const updateAdminApi = ({data,token}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/admin/update",
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};


export const deleteApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/delete`,{
                    data: {id:id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}




// ************************************************ Content Management/FAQ *****************************************************

export const getAllFaqApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                `/admin/faq`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const addFaqpi=({token,faqData})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.post(
                "/admin/faq",
                {   title:faqData.question,
                    content: faqData.answer,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const editFaqApi = ({faqData,token}) => {
    console.log("d",faqData);
    
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Axios.put(
                "/admin/faq",
                {   id:faqData?.id,
                    title:faqData?.question,
                    content:faqData?.answer
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    });
};

export const deleteFaqApi=({token,id})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.delete(
                `/admin/faq`,{
                    data: {id:id},
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  });
            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

// ************************************************ Chef Sections  *****************************************************

export const getChefsCountApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                "/admin/chef-count",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const getChefsCountInMonthApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                "/admin/chefs-this-month",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}








// ************************************************ Guests Sections  *****************************************************

export const getGuestsCountApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                "/admin/user-count",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}

export const getGuestsCountInMonthApi=({token})=>{
    return new Promise(async(resolve,reject)=>{
        try {
            const response = await Axios.get(
                "/admin/users-this-month",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            resolve(response);
        } catch (error) {
                console.log("error",error);
                
            reject(error.response);
        }
    })
}