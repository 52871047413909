import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Box,
  Avatar,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const GuestsEventRow = ({ row }) => {
  const navigate = useNavigate();

  return (
    <>
      <TableRow>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // flexWrap: "wrap",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                fontFamily: "Rosario",

                fontWeight: 500,
                fontSize: "17px",
              }}
            >
              {row.id || "-"}
            </div>
          </Box>
        </TableCell>

        <TableCell
          onClick={() =>
            navigate(
              "/dashboard/guests/all-guests/guests-information/guests-events/events-details/:id"
            )
          }
          sx={{
            color: "#1877F2",
            fontFamily: "Rosario",
            textDecoration: "underline",
            fontWeight: 500,
            fontSize: "17px",
          }}
        >
          <div style={{ cursor: "pointer" }}>{row.email}</div>
        </TableCell>

        <TableCell
          sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
        >
          <div>{row.experience}</div>
        </TableCell>

        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontWeight: 500,
            fontSize: "17px",
          }}
        >
          <div>{row.joineddate}</div>
        </TableCell>

        <TableCell
          sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
        >
          <div>{row.events}</div>
        </TableCell>

        <TableCell
          sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
        >
          <div>{row.earnings}</div>
        </TableCell>
        <TableCell
          sx={{
            fontFamily: "Rosario",
            fontWeight: 500,
            fontSize: "17px",
            color: row.paymentStatus==="Done"?'#009048' : "#FFC107",
            
          }}
        >
          <div>{row.paymentStatus}</div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default GuestsEventRow;
