import React from 'react'
import TicketBtn from '../../common/Ticket/TicketBtn'
import { Link } from 'react-router-dom';


const GuestsTicketManagement = () => {
  
  
  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/guests" className="All-color-breakcurm">
      Guest
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Ticket Management</span>,
  ];
  return (
    <div>

   <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>
      
     <div className='ticket-management-btn-container'>
         <TicketBtn/>
     </div>
    </div>
  )
}

export default GuestsTicketManagement