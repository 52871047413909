import React from 'react'
import Card1 from '../../common/card1/Card1'
import Commision from '../../common/assest/commision.png'
import CommisionLogo from '../../common/assest/commisionimg.png'
import './CommisionManagement.css';

import { Link } from 'react-router-dom';
import TableComp from '../../common/tablecomp/TableComp';
import CommissionRow from '../../common/tablerow/CommissionRow';
import TableCard from '../../common/tableCard/TableCard';
import Pagination from '../../common/pagintaion/Pagination';
import SearchBar from '../../common/searchbar/SearchBar';


const CommisionManagement = () => {

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Commision Management</span>,
  ];

  const style = {
    cursor: 'default'
  }

  const titles = [
    { title: "Chef Name" },
    { title: "Event Name" },
    { title: "Event Date" },
    { title: "Total Guests" },
    { title: "Total Payment" },
    { title: "Commission Received" },
  ];

  return (
    <>

      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>


      <div className='commision-card-container'>
        <Card1 text="Total Transactions" value="400" img={CommisionLogo} style={style} />
        <Card1 text="Total Commission Received" value="$67890" img={Commision} style={style} />
      </div>

      <TableCard>
        <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:'center', flexWrap: 'wrap', padding: '20px', fontFamily: "Rosario", fontWeight: 600,fontSize:'20px' }}>
          <div>Commission Details</div>
          <div><SearchBar/></div>
        </div>
        <TableComp title={titles}>
          <CommissionRow />
        </TableComp>


      </TableCard>

      <div className='txt-sample'>
        Copyright © 2024 Chef’s Table All rights reserved.
      </div>
    </>
  )
}

export default CommisionManagement