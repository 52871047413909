import React, { useState } from "react";
import "./CreateNotification.css";
import CustomCard1 from "../../common/customCard1/CustomCard1";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CarbonAvtar from "../../common/assest/carbonavatar.svg";
import AddIcon from "@mui/icons-material/Add";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import CustomModal from "../../common/modal/CustomModal";
import ClearIcon from "@mui/icons-material/Clear";
import SearchItem from '../../common/assest/searchIcon.svg'
import userimg from "../../common/assest/usrimg.png";

const CreateNotification = () => {
  const [open, setOpen] = useState(false);

  const [selectedType, setSelectedType] = useState(null);

  const handleChangesUser = (event) => {
    setSelectedType(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectUserModal = (item) => {
    setOpen(true);
  };

  
  return (
    <div>
      <CustomCard1 >
        <Grid container spacing={4}>
          <div className="create-push-notification">
            Create New Push Notification
          </div>

          {/* Second Column: Notification form */}
          <Grid item xs={12} md={8}>
            <div className="create-notification">
              <Grid container spacing={2}>
                {/* Notification Title */}
                <Grid item xs={12}>
                  <Typography className="acc-management-text">
                    Notification Title
                  </Typography>
                  <TextField
                    type="text"
                    placeholder="Enter Notification Title"
                    name="lastname"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        height: "45px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "28px",
                        backgroundColor: "#F0F0F0",
                        fontSize: "14px",
                        fontFamily: "Rosario",
                      },
                    }}
                  />
                </Grid>

                {/* Notification Description */}
                <Grid item xs={12}>
                  <Typography className="acc-management-text">
                    Notification Description
                  </Typography>
                  <textarea
                    placeholder="Enter your Description"
                    rows={6}
                    cols={89}
                    style={{
                      width: "100%",
                      border: "none",
                      backgroundColor: "#F0F0F0",
                      borderRadius: "25px",
                      resize: "none",
                      outline: "none",
                      textIndent: "20px",
                      paddingTop: "20px",
                      minHeight: "100px",
                    }}
                  ></textarea>
                </Grid>
              </Grid>

              {/* Date and Time of Publishing in two columns */}
              <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                <Grid item xs={12} sm={6}>
                  <Typography className="acc-management-text">
                    Date of Publishing
                  </Typography>
                  <TextField
                    type="date"
                    placeholder="Enter Date"
                    name="dateOfPublishing"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        height: "45px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "28px",
                        backgroundColor: "#F0F0F0",
                        fontSize: "14px",
                        fontFamily: "Rosario",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className="acc-management-text">
                    Time of Publishing
                  </Typography>
                  <TextField
                    type="time"
                    placeholder="Enter Time"
                    name="timeOfPublishing"
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        height: "45px",
                      },
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "28px",
                        backgroundColor: "#F0F0F0",
                        fontSize: "14px",
                        fontFamily: "Rosario",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} mt={4}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap:'wrap',
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontFamily: "Rosario" }}>
                      Send Notification
                    </div>
                    <div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          value={selectedType}
                          onChange={handleChangesUser}
                          row
                        >
                          <FormControlLabel
                            value="alluser"
                            control={
                              <Radio
                                sx={{
                                  "& .MuiRadio-root": {
                                    position: "relative",
                                  },
                                  "&:before": {
                                    content: '""',
                                    position: "absolute",
                                    width: 28,
                                    height: 28,
                                    borderRadius: "50%",
                                    border: "2px solid white",

                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                  },
                                }}
                              />
                            }
                            label="To all User"
                            fontSize="14px"
                            fontFamily="Rosario"
                          />
                          <FormControlLabel
                            value="selectedUser"
                            control={
                              <Radio
                                sx={{
                                  fontFamily: "Rosario",

                                  "& .MuiRadio-root": {
                                    position: "relative",
                                  },
                                  "&:before": {
                                    content: '""',
                                    position: "absolute",
                                    width: 28,
                                    height: 28,
                                    borderRadius: "50%",
                                    border: "2px solid white",

                                    left: "50%",
                                    top: "50%",
                                    transform: "translate(-50%, -50%)",
                                  },
                                }}
                              />
                            }
                            label="To Selected User"
                            fontSize="14px"
                            fontFamily="Rosario"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  {selectedType === "selectedUser" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: 1,
                        mt: 2,
                      }}
                    >
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                        <AvatarGroup
                          renderSurplus={(surplus) => (
                            <span>+{surplus.toString()[0]}k</span>
                          )}
                          total={4}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          <Avatar
                            alt="Remy Sharp"
                            src={CarbonAvtar}
                            sx={{ width: "25px", height: "25px" }}
                          />
                          <Avatar
                            alt="Travis Howard"
                            src={CarbonAvtar}
                            sx={{ width: "25px", height: "25px" }}
                          />
                          <Avatar
                            alt="Agnes Walker"
                            src={CarbonAvtar}
                            sx={{ width: "25px", height: "25px" }}
                          />
                          <Avatar
                            alt="Trevor Henderson"
                            src={CarbonAvtar}
                            sx={{ width: "25px", height: "25px" }}
                          />
                        </AvatarGroup>

                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#362B24",
                            fontFamily: "Rosario",
                            fontSize: { xs: "18px", sm: "18px" },
                          }}
                        >
                          2.4k Attendance
                        </Typography>
                      </Box>
                      <Button
                        onClick={handleSelectUserModal}
                        sx={{
                          mt: { xs: 2, sm: 0 },
                          width: "100px",
                          height: "42px",
                          flexShrink: 0,
                          borderRadius: "28px",
                          background: "#EA1D26",
                          boxShadow: "1px 2px 30px 0px rgba(0, 0, 0, 0.06)",
                          color: "white",
                          textTransform: "none",
                          fontFamily: "Rosario",
                          "&:hover": {
                            opacity: 0.8,
                          },
                        }}
                      >
                        <AddIcon sx={{ fontSize: "14px" }} /> Add
                      </Button>
                    </Box>
                  )}

                  <div style={{ marginTop: "20px" }}>
                    <CustomBtn1 text="Save" />
                  </div>

                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CustomCard1>

      <CustomModal open={open} handleClose={handleClose}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#6E6E6E",
            padding:'5px'
          }}
        >
          <div
            style={{ color: "#120B1C", fontFamily: "Rosario", fontWeight: 700 }}
          >
            Select User
          </div>
          <div
            style={{
              backgroundColor: "#EBEBEB",
              height: "26px",
              width: "26px",
              borderRadius: "25px",
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              cursor:'pointer'
            }}
            onClick={handleClose}
          >
            {" "}
            <ClearIcon sx={{color:'black',fontSize:"18px"}}/>
          </div>
        </div>

        <TextField
          
           placeholder="Search..."
           fullWidth
          
           sx={{
              marginTop:'10px',
             height: {xs:45,sm:45},
             flexShrink: 0,
             borderRadius: "33px",
             background: "#F6F5F6",
             
             border: "none", 
             "& .MuiOutlinedInput-root": {
               borderRadius: "33px",
               height: "100%",
               paddingRight: "10px",
               outline: "none",
               border: "none", 
             },
             "& .MuiOutlinedInput-notchedOutline": {
               border: "none", 
             },
           }}
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">
                  <img src={SearchItem}
                   sx={{ color: "#000", opacity: "0.35" }}
                 />
               </InputAdornment>
             ),
             style: { height: "100%" },
           }}
         />

         <div className="notification-user-container">
               <div className="user-name-image">
                <div><img src={userimg} /></div>
                  <div>Jonathan Williams</div>
               </div>
               
                <div>

                </div>
         </div>

         <CustomBtn1 text="ADD" onClick={handleClose}/>

      </CustomModal>
    </div>
  );
};

export default CreateNotification;
