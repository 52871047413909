import React from 'react';
import { Box, Modal } from '@mui/material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '500px', 
  height:"300px",
  overflow:'scroll',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius:"25px",
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',  
  '@media (max-width: 600px)': {
    width: '100%', 
    maxWidth: 'none',
    padding: '16px', 
  },
  '@media (max-width: 600px)': {
    width: '70%', 
  },
  '@media (min-width: 1600px)': {
    height:"auto",
  },
  
};

const CustomModal = ({ open, handleClose, children }) => {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box sx={style}>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
