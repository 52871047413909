import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { height, styled } from '@mui/system';
import src from '@emotion/styled';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: '25px',
  marginTop:'10px',
  background: '#FFF',
  boxShadow: '2px 4px 20px 0px rgba(0, 0, 0, 0.04)',
  width: '100%', 
  maxWidth: '260px', 
  height: 'auto',
  fontFamily:'Rosario',
  textAlign:'center',
  cursor:"pointer",
  flexShrink: 0,
  [theme.breakpoints.down('sm')]: {
    height: 'auto', 
  },
}));

const Card1 = ({text,value,img,onClick,style,value1}) => {
  return (
    <StyledCard onClick={onClick} style={style}>
       {img ? (
        <img
        component="img"
        alt="logo"
        height='95px'
        src={img}
      />
      
       ):(
        ""
       )}  
      {value1 ? (
        <Typography  component="div" sx={{fontFamily:'Rosario',fontSize:'26px',fontWeight:'700',color:'#EA1D26',wordBreak:"break-all",}}>
           {value} <span style={{color:'#737373',fontSize:'17px'}}>{value1}</span>
        </Typography>
      ):(
        <Typography  component="div" sx={{fontFamily:'Rosario',fontSize:'26px',fontWeight:'700',color:'#EA1D26',wordBreak:"break-all",}}>
       {value}
      </Typography>
      )}
        <Typography  component="div"sx={{fontFamily:'Rosario',fontSize:'19px',wordBreak:"break-all",marginBottom:"15px",fontWeight:'600'}} >
          {text}
        </Typography>
      
      
    </StyledCard>
  );
};

export default Card1;

