import React, { useState, useEffect } from "react";
import CustomCard1 from "../../common/customCard1/CustomCard1";
import ImgUploader from "../../common/imguploader/ImgUploader";
import EyeClose from "../../common/assest/ion_eye-off.svg";
import EyeOpen from "../../common/assest/opnEye.svg";

import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {  deleteApi, updateAdminApi } from '../../api';
import './AccManagement.css';
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/userSlice";
import Loader from "../../common/loader/Loader";

const AccManagement = () => {
  
  const token = useSelector((state) => state?.user?.user?.token);
  const users = useSelector((state) => state?.user?.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profilePicture, setProfilePicture] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    bio: "",
    password: "",
    confirmPassword: "",
  });
  const [loading,setLoading]=useState(false)

  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    confirmPassword: false,
  });
  const togglePasswordVisibility = (field) => {
    setPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };
  useEffect(() => {
    if (users) {
      setFormData({
        firstName: users?.full_name||"",
        email: users?.email||"",
        phoneNumber: users?.phone_number||"",
        bio: users?.bio||"",
        profilePicture: users?.profile_picture || null,
      });
    }
  }, [users]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "" || value === "") {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };


  const handleSubmit = async () => {
    const isConfirmed = window.confirm("Are you sure you want to update your details?");
    if (!isConfirmed) return;

    if (formData.password !== formData.confirmPassword) {
      toast.warning("Passwords do not match.");
      return;
    }
    try {
      const submitData = new FormData();
      submitData.append("id", users._id);
      submitData.append("full_name", formData.firstName);
      submitData.append("email", formData.email);
      submitData.append("phone_number", formData.phoneNumber);
      submitData.append("bio", formData.bio || "");

      if (formData.confirmPassword) {
        submitData.append("password", formData.confirmPassword);
      }
      if (profilePicture) {
        submitData.append("profile_picture", profilePicture);
      }
      setLoading(true)
      const response = await updateAdminApi({
        token,
        data: submitData,
      });
      dispatch(updateUser(response?.data));
      setLoading(false)
      const successMsg = response?.message;
      if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      }
    } catch (error) {
      setLoading(false)
      console.error('Error updating profile:', error);
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  // Handle account deletion
  const deleteAccount = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete your account?");
    if (confirmDelete) {
      try {
        const response = await deleteApi({ token, id: users._id });
        const successMsg = response?.message;
        if (Array.isArray(successMsg)) {
          successMsg.forEach((msg) => toast.success(msg));
        } else if (successMsg) {
          toast.success(successMsg);
        }
        setTimeout(() => {
          navigate("/");
        }, 2000);

      } catch (error) {
        console.error('Error deleting account:', error);
        const errorMessage = error?.data?.error;
        if (Array.isArray(errorMessage)) {
          errorMessage.forEach((msg) => toast.error(msg));
        } else if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    }
  };

  const handleProfilePictureChange = (file) => {
    setProfilePicture(file); 
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Account Management</span>,
  ];

  return (
    <>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>
{loading ? (
<Loader/>
):(

  <>
  <CustomCard1>
        <Grid container spacing={2} justifyContent="center">
          {/* Left Side: Image Uploader */}
          <Grid item xs={12} sm={12} md={2} display="flex" justifyContent="center">
          <ImgUploader initialProfile={users?.profile_picture} onProfilePictureChange={handleProfilePictureChange} />
          </Grid>

          {/* Right Side: Input Fields */}
          <Grid item xs={12} sm={12} md={10}>
            <Grid container spacing={2}>
              {/* First Name and Email */}
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Full Name</Typography>
                <TextField
                  type="text"
                  placeholder="Enter Full Name"
                  value={formData.firstName}
                  name="firstName"
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: '45px',
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />

                <Typography className="acc-management-text" sx={{ marginTop: '10px' }}>
                  Email Id
                </Typography>
                <TextField

                  type="email"
                  disabled
                  value={formData.email}
                  name="email"
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: '45px',
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />
              </Grid>

              {/* Last Name and Phone Number */}
              <Grid item xs={12} sm={6}>
                {/* <Typography className="acc-management-text">Last Name</Typography>
                <TextField
                  type="text"
                  value={formData.lastName}
                  name="lastName"
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: '45px',
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                /> */}

                <Typography className="acc-management-text" sx={{ marginTop: '10px' }}>
                  Phone Number
                </Typography>
                <TextField
                  type="text"
                  placeholder="Enter Phone Number"
                  value={formData.phoneNumber}
                  name="phoneNumber"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) {  
                      handleInputChange(e);
                    }
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: '45px',
                    },
                  }}
                  InputProps={{
                    inputProps: { maxLength: 10 },
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />
              </Grid>

              {/* Bio */}
              <Grid item xs={12} sm={12}>
                <Typography className="acc-management-text">Your Bio</Typography>
                <textarea
                 placeholder="Enter Bio"
                  value={formData.bio}
                  name="bio"
                  onChange={handleInputChange}
                  rows={6}
                  cols={89}
                  style={{
                    width: "100%", border: 'none', backgroundColor: "#F0F0F0",
                    borderRadius: "25px", resize: "none", outline: 'none', textIndent: '20px', paddingTop: '20px', minHeight: '100px'
                  }}
                />
              </Grid>

              {/* Password Fields */}
              <Grid item xs={12} sm={6}>
                  <Typography className="acc-management-text">New Password</Typography>
                  <TextField
                  placeholder="Enter New Password"
                    type={passwordVisible.password ? "text" : "password"}
                    value={formData.password}
                    name="password"
                    onChange={handleInputChange}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        height: '45px',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <img
                          src={passwordVisible.password ? EyeOpen : EyeClose}
                          alt="toggle password visibility"
                          style={{ cursor: 'pointer' }}
                          onClick={() => togglePasswordVisibility("password")}
                        />
                      ),
                      style: {
                        borderRadius: "28px",
                        backgroundColor: "#F0F0F0",
                        fontSize: "14px",
                        fontFamily: "Rosario",
                      },
                    }}
                  />
                </Grid>

              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Re-Enter New Password</Typography>
                <TextField
                placeholder="Re-Enter New Password"
                  type={passwordVisible.confirmPassword ? "text" : "password"}
                  value={formData.confirmPassword}
                  name="confirmPassword"
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: '45px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <img
                        src={passwordVisible.confirmPassword ? EyeOpen : EyeClose}
                        alt="toggle confirm password visibility"
                        style={{ cursor: 'pointer' }}
                        onClick={() => togglePasswordVisibility("confirmPassword")}
                      />
                    ),
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />
              </Grid>


              {/* Update and Delete Buttons */}
              <Grid item xs={12}>
                <CustomBtn1
                  text="Save Your Profile"
                  className="button-full-width"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomCard1>

      <div className="delete-account" onClick={deleteAccount}>
        Delete Account?
      </div>
  </>
)}
    
    </>
  );
};

export default AccManagement;
