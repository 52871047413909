// import {
//   Card,
//   Tbody,
//   Tr,
//   Thead,
//   Table,
//   Th,
//   Checkbox,
//   TableContainer,
//   Divider,
//   useBreakpointValue,
// } from "@chakra-ui/react";
import { CheckBox } from "@mui/icons-material";
import { Card, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useState } from "react";
import Paginations from "../pagintaion/Pagination";

 
const TableComp = ({
  title,
  children,
  emptySColumnCount = 0,
  emptyEColumnCount = 0,
  pagination = { currentPage: 0, totalPages: 0, pageSize: 0, totalItems:0 },
  displayPagination = false,
  onPageChange,
}) => {
//   const cardPadding = useBreakpointValue({ base: 4, md: 6, lg: 10 });
//   const tableSize = useBreakpointValue({ base: "sm", md: "md", lg: "lg" });
//   const checkboxWidth = useBreakpointValue({ base: "5%", md: "9%" });
//   const emptyColumnWidth = useBreakpointValue({ base: "10%", md: "20%" });
//   const fontSize = useBreakpointValue({ base: "xs", md: "sm", lg: "md" });
 
  const [currentPage, setCurrentPage] = useState(pagination.currentPage);
 
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    if (onPageChange) {
      onPageChange({ page: newPage });
    }
  };
 
  const renderEmptyColumns = (count) => {
    const columns = [];
    for (let i = 0; i < count; i++) {
      columns.push(<TableCell key={`empty-${i}`} w={"200px"}></TableCell>);
    }
    return columns;
  };
 
  return (
    <Card
      backgroundColor={"#fff"}
      borderRadius={"35px"}
    //   style={{
    //     boxShadow: "0px 10px 99px #00000029",
    //   }}
    //   textAlign={"center"}
    //   align={"center"}
      w={"100%"}
      h={"auto"}
     sx={{padding:'20px'}}
    >
      <TableContainer
        w={"full"}
        maxH={"80vh"}
        overflowY={"auto"}
        overflowX={"auto"}
        size={"medium"}
      >
        <Table  size={"medium"}>
          <TableHead>
            <TableRow >
              {renderEmptyColumns(emptySColumnCount)}
              {title &&
                title.map((item, index) => (
                  <TableCell
                    key={index}
                    color={"#000"}
                    // fontSize={fontSize}
                    whiteSpace="pre-wrap"
                    wordWrap={true}
                    textOverflow="ellipsis"
                    sx={{fontFamily:"Rosario",fontWeight:'600',fontSize:'18px'}}
                  >
                    {item.title}
                  </TableCell>
                ))}
              {renderEmptyColumns(emptyEColumnCount)}
            </TableRow>
          </TableHead>
          {/* <Divider /> */}
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
 
      {/* Pagination Component */}
      {/* {displayPagination && (
        <Pagination
          currentPage={currentPage}
          totalPages={pagination.totalPages}
          onPageChange={handlePageChange}
        />
      )} */}
       <Paginations
        // currentPage={currentPage}
        // totalPages={pagination.totalPages}
        // onPageChange={handlePageChange}
       />
    </Card>
  );
};
 
export default TableComp;