import React, { useState } from 'react';
import './UpdateProfile.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomCard1 from '../../common/customCard1/CustomCard1';
import { Grid, TextField, Typography } from '@mui/material';
import ImgUploader from '../../common/imguploader/ImgUploader';
import CustomBtn1 from '../../common/customBtn1/CustomBtn1';
import {  deleteApi, updateAdminApi } from '../../api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const UpdateProfile = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const { state } = useLocation(); 
  const navigate = useNavigate();

  const [profilePicture, setProfilePicture] = useState(null);

  const [formData, setFormData] = useState({
    fullName: state?.full_name || '',
    email: state?.email || '',
    phoneNumber: state?.phone_number || '',
    bio: state?.bio || '',
    profilePicture: state?.profile_picture || null,
  });

  // Update form data on input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "" || value === "") {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };


 
  const handleProfilePictureChange = (file) => {
    setProfilePicture(file); 
  };

  // Handle form submission
  const handleSubmit = async () => {
    const isConfirmed = window.confirm("Are you sure you want to update your details?");
    if (!isConfirmed) return;

    try {
      const submitData = new FormData();
      submitData.append("id", state._id);
      submitData.append("full_name", formData.fullName);
      submitData.append("email", formData.email);
      submitData.append("phone_number", formData.phoneNumber);
      submitData.append("bio", formData.bio);

      if (profilePicture) {  
        submitData.append("profile_picture", profilePicture); 
      }

      const response = await updateAdminApi({
        token,
        data: submitData,
      });

      const successMsg = response?.message;
      if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  // Handle account deletion
  const deleteAccount = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete your account?");
    if (confirmDelete) {
      try {
        const response = await deleteApi({ token, id: state._id });
        const successMsg = response?.message;
        if (Array.isArray(successMsg)) {
          successMsg.forEach((msg) => toast.success(msg));
        } else if (successMsg) {
          toast.success(successMsg);
        }
        navigate(-1);
      } catch (error) {
        console.error('Error deleting account:', error);
        const errorMessage = error?.data?.error;
        if (Array.isArray(errorMessage)) {
          errorMessage.forEach((msg) => toast.error(msg));
        } else if (errorMessage) {
          toast.error(errorMessage);
        }
      }
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">Home</Link>,
    <Link key="admin-management" to="/dashboard/admin-management" className="All-color-breakcurm">Admin Management</Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;{state?.full_name}</span>,
  ];

  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <CustomCard1>
        <Grid container spacing={6} justifyContent="center">
          {/* Left Side: Image Uploader */}
          <Grid item xs={12} sm={12} md={2} display="flex" justifyContent="center">
            <ImgUploader initialProfile={state?.profile_picture} onProfilePictureChange={handleProfilePictureChange} />
          </Grid>

          {/* Right Side: Input Fields */}
          <Grid item xs={12} sm={12} md={10}>
            <Grid container spacing={2}>
              {/* Full Name and Phone Number */}
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Full Name</Typography>
                <TextField
                  type="text"
                  placeholder="Enter your Full Name"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: '45px',
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />

                <Typography className="acc-management-text" sx={{ marginTop: '10px' }}>Phone Number</Typography>
                <TextField
                  type="text"
                  placeholder="Enter your Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,10}$/.test(value)) {  
                      handleInputChange(e);
                    }
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: '45px',
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={6}>
                <Typography className="acc-management-text">Email</Typography>
                <TextField
                  type="email"
                  placeholder="Enter Your Email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: '45px',
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />
              </Grid>

              {/* Bio */}
              <Grid item xs={12} sm={12} sx={{ marginTop: "5px" }}>
                <Typography className="acc-management-text">Your Bio</Typography>
                <textarea
                  rows={6}
                  name="bio"
                  value={formData.bio}
                  onChange={handleInputChange}
                  style={{
                    width: "100%",
                    border: 'none',
                    backgroundColor: "#F0F0F0",
                    borderRadius: "25px",
                    resize: "none",
                    outline: 'none',
                    textIndent: '20px',
                    paddingTop: '20px',
                    minHeight: '100px'
                  }}
                ></textarea>
              </Grid>

              {/* Update Button */}
              <Grid item xs={12} sm={12} sx={{ marginTop: '12px' }}>
                <CustomBtn1 text="Update" onClick={handleSubmit} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CustomCard1>

      <div className="delete-account" onClick={deleteAccount}>
        Delete Account?
      </div>
    </div>
  );
};

export default UpdateProfile;
