import React, { useState } from 'react'
import Card2 from '../../common/card2/Card2'
import './Settings.css';
import { Link, useNavigate } from 'react-router-dom';

export const Setting = () => {

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);


  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Settings</span>,
  ];


  // const cardStyle = {
  //   border: `1px solid ${isHovered ? '#EA1D26' : 'none'}`,
  //   borderRadius: '25px',
  //   padding: '20px',
  // };

  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>


      <Card2 
        onClick={()=> navigate('/dashboard/settings/push-notification')} 
        // style={cardStyle} 
        // onMouseEnter={() => setIsHovered(true)}
        // onMouseLeave={() => setIsHovered(false)}
        >

        <div className='push-notification-text'>
          <div>Push Notifications</div>
        </div>
      </Card2>
    </div>
  )
}
