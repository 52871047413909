import React from 'react'

import Card1 from '../../common/card1/Card1'
import SearchBar from '../../common/searchbar/SearchBar'
import TableCard from '../../common/tableCard/TableCard'
import TableComp from '../../common/tablecomp/TableComp'
import ChefRow from '../../common/tablerow/ChefRow'

import { Link } from 'react-router-dom'
import EarningsRow from '../../common/tablerow/EarningsRow'

const ChefEarnings = () => {

    const style = {
        minHeight: "95px",
        paddingTop: "22px",
        cursor: "default",
      };
    const titles = [
        { title: "Sr No" },
        { title: "Event Name" },
        { title: "Address" },
        { title: "Date" },
        { title: "Attendees" },
        { title: "Earnings" },
      ];
      const rows = [
        {
         
        id:1,
          email: "ABC",
          experience: " 13th Street. 47 W 13th St, New York, NY 10011, USA. ",
          events: "150",
         
          earnings: "$5677",
          joineddate: "05/16/2022",
        
        },
        {
         
          id:2,
          email: "ABC",
          experience: " 13th Street. 47 W 13th St, New York, NY 10011, USA. ",
          events: "150",
        
          earnings: "$5677",
          joineddate: "05/16/2022",
          
        },
        
      ];

     const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/chefs/all-chefs"
      className="All-color-breakcurm"
    >
      Chef’s Details
    </Link>,
    <Link
    key="home"
    to="/dashboard/chefs/all-chefs/chefs-information"
    className="All-color-breakcurm"
  >
    Chef’s Name
  </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Earnings</span>,
  ];
  return (
    <div>

          <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>



         <div className="chef-details-card-container">
        <Card1 value="$2000.00" text="Total Earnings  >" style={style} />
       
      </div>

      <div className='chef-detail-tble-wrapper'>
      <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
            gap:'10px'
          }}
        >
          <div>Earnings Details</div>
          <div>
            <SearchBar />
          </div>
        </div>

        <TableComp title={titles}>
          {rows.map((item) => (
            <EarningsRow  row={item} />
          ))}
        </TableComp>
      </TableCard>
      </div>
    </div>
  )
}

export default ChefEarnings