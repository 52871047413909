import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import SendIcon from '@mui/icons-material/Send';
import "./Ticket.css";

const AllTicket = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedTicket(null);
  };

  const tickets = [
    {
      id: 1,
      title: "Ticket 1",
      date: "Jan 01, 2022",
      description:
        "Lorem ipsum dolor sit amet consectetur. Facilisi viverra orci sed pharetra vitae blandit...",
      priority: "High",
      status: "Inprocess",
    },
    {
      id: 2,
      title: "Ticket 2",
      date: "Feb 10, 2022",
      description:
        "Phasellus sit amet turpis viverra, malesuada purus ac, vestibulum urna...",
      priority: "Medium",
      status: "Completed",
    },
    {
      id: 3,
      title: "Ticket 3",
      date: "Mar 05, 2022",
      description:
        "Quisque egestas orci at urna tincidunt, nec scelerisque nulla.",
      priority: "Low",
      status: "Inprocess",
    },
  ];

  return (
    <>
      {tickets.map((ticket) => (
        <div
          className="ticket-container"
          style={{ marginTop: "20px" }}
          key={ticket.id}
          onClick={() => handleTicketClick(ticket)}
        >
          <div class="ticket-card">
            <div class="ticket-column">
              <div class="ticket-box title">{ticket.title}</div>
              <div class="ticket-box datess">{ticket.date}</div>
              <div class="ticket-box ticket-description">
                {ticket.description}
              </div>
              <div class="ticket-box status-priority-container">
                <div className="ticket-priority">
                  Priority:{" "}
                  <span style={{ color: "#EA1D26" }}>{ticket.priority}</span>
                </div>
                <div className="ticket-status">
                  Status:{" "}
                  <span style={{ color: "#FFC107" }}>{ticket.status}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {/* MUI Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            borderRadius: "25px 0px 0px 25px", // Drawer border radius
            // width: '300px', (optional)
          },
        }}
      >
        {selectedTicket && (
          <div
            className="drawer-content"
            style={{ width: "283px", padding: "20px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div>
                <div
                  style={{
                    color: "#EA1D26",
                    fontFamily: "Rosario",
                    fontSize: "24px",
                  }}
                >
                  Reply to John
                </div>
                <div style={{ color: "#707070", fontFamily: "Rosario" }}>
                  john@email.com
                </div>
              </div>
              <button
                className="close-drawer"
                onClick={handleCloseDrawer}
                style={{
                  border: "none",
                  background: "none",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
              >
                X
              </button>
            </div>

            {/* Content inside the drawer */}
            <div className="title" style={{ marginTop: "20px" }}>
              {selectedTicket.title}
            </div>
            <p>{selectedTicket.description}</p>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                marginTop: "20px",
                // height:'80%'
              }}
            >
              {/* Priority Section */}
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" ,alignItems:'center',}}>
                <div
                  style={{
                    color: "#EA1D26",
                    fontFamily: "Rosario",
                    fontSize: "16px",
                  }}
                >
                  Priority :
                </div>
                <div style={{ color: "#707070", fontFamily: "Rosario" }}>
                  {selectedTicket.priority}
                </div>
              </div>

              {/* Dropdown for Priority */}

              <div>
                <select className="form-select" style={{width:'128px',height:'34px',textAlign:'center',borderRadius:'5px',background:'#F6F6F6',border:'1px solid #A0A0A0',color:'#707070'}}>
                
                  <option selected >Inprocess</option>
                  <option value="1">Resolved</option>
               
                </select>
              </div>




              <div className="chat-section-container">
                    chatting
              </div>


            <div className="send-chat-reply-btn-container">
                    <div><input type="text" className="chat-input" placeholder="Enter your message"/></div>
                    
                    <div className="send-btn-icon">
                            <SendIcon/>
                    </div>
            </div>


            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default AllTicket;
