import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import CustomBtn1 from "../../common/customBtn1/CustomBtn1";
import ClearIcon from "@mui/icons-material/Clear";
import "./FAQ.css";
import EditIcon from "../../common/assest/editfaq.svg";
import DeleteIcon from "../../common/assest/deletefaq.svg";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { addFaqpi, deleteFaqApi, editFaqApi, getAllFaqApi } from "../../api";
import Loader from "../../common/loader/Loader";

const FAQ = () => {
  const token = useSelector((state) => state?.user?.user?.token);
  const modalWidth = window.innerWidth < 500 ? "90%" : "90%";
  const maxWidth = window.innerWidth < 576 ? "400px" : "590px";

  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [faqData, setFaqData] = useState({ question: "", answer: "" });
  const [currentFaq, setCurrentFaq] = useState(null);

  const [change, setChange] = useState(false);
  const [allFaq, setAllFaq] = useState([]);
  const [loading, setLoading] = useState(false);

  const openModal = (editMode = false, faq = null) => {
    setIsEditMode(editMode);
    if (editMode && faq) {
      setCurrentFaq(faq);
      setFaqData({ question: faq.title, answer: faq.content });
    } else {
      setFaqData({ question: "", answer: "" });
    }
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setIsEditMode(false);
    setFaqData({ question: "", answer: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() !== "" || value === "") {
      setFaqData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const fetchFaq = async () => {
    try {
      setLoading(true);
      const response = await getAllFaqApi({ token });
      setAllFaq(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching FAQs:", error);
    }
  };

  useEffect(() => {
    fetchFaq();
  }, [change]);

  const handleAddFaq = async () => {
    try {
      const response = await addFaqpi({ token, faqData });
      const successMsg = response?.message;
      if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      } else {
        toast.success("FAQ Created");
      }
      setChange(!change);
      closeModal();
    } catch (error) {
      console.error("Error adding FAQ:", error);
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Some error occurred");
      }
    }
  };

  const handleEditFaq = async () => {
    try {
      const response = await editFaqApi({
        token,
        faqData: {
          id: currentFaq._id,
          ...faqData,
        },
      });
      const successMsg = response?.message;
      if (Array.isArray(successMsg))if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      } else {
        toast.success("FAQ Updated");
      }
      setChange(!change);
      closeModal();
    } catch (error) {
      console.error("Error editing FAQ:", error);
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Some error occurred");
      }
    }
  };

  const handleDeleteFaq = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this FAQ?"
    );
    if (!confirmDelete) return;
    try {
      const response = await deleteFaqApi({ token, id });
      const successMsg = response?.message;
      if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      } else {
        toast.success("FAQ deleted");
      }
      setChange(!change);
    } catch (error) {
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error("Some error occurred");
      }
    }
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link
      key="content-management"
      to="/dashboard/content-management"
      className="All-color-breakcurm"
    >
      Content Management
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;FAQ</span>,
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: maxWidth,
    width: modalWidth,
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: 24,
    p: 0,
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <div className="create-btn-container">
            <Button
              onClick={() => openModal(false)}
              variant="contained"
              className="create-admin-btn"
              startIcon={
                <AddCircleOutlineOutlinedIcon className="create-admin-icon" />
              }
            >
              Create New FAQ
            </Button>
          </div>

          {/* Modal */}
          <Modal open={open} onClose={closeModal}>
            <Box sx={style}>
              <div className="create-modal-heading">
                <div>{isEditMode ? "Edit FAQ" : "Create New FAQ"}</div>
                <ClearIcon onClick={closeModal} style={{ cursor: "pointer" }} />
              </div>

              <div style={{ padding: "20px" }}>
                <Typography className="acc-management-text">FAQ Question</Typography>
                <TextField
                  type="text"
                  placeholder="Enter here"
                  name="question"
                  value={faqData.question}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      height: "45px",
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />
              </div>

              <div style={{ padding: "20px", marginTop: "-20px" }}>
                <Typography className="acc-management-text">FAQ Answer</Typography>
                <textarea
                  placeholder="Enter here"
                  name="answer"
                  value={faqData.answer}
                  onChange={handleInputChange}
                  rows={6}
                  cols={89}
                  style={{
                    width: "100%",
                    border: "none",
                    backgroundColor: "#F0F0F0",
                    borderRadius: "25px",
                    resize: "none",
                    outline: "none",
                    textIndent: "20px",
                    paddingTop: "20px",
                    minHeight: "100px",
                  }}
                ></textarea>

                <div style={{ marginTop: "20px" }}>
                  <CustomBtn1
                    text={isEditMode ? "Update FAQ" : "Add New FAQ"}
                    onClick={isEditMode ? handleEditFaq : handleAddFaq}
                  />
                </div>
              </div>
            </Box>
          </Modal>

          {/* FAQ display */}
          <>
            {allFaq.length > 0 ? (
              allFaq.map((faqs, index) => (
                <div className="FAQ-whole-container" key={index}>
                  <div className="faq-btn-container">
                    <div
                      className="edit-btn"
                      onClick={() => openModal(true, faqs)}
                    >
                      <img src={EditIcon} alt="edit-icon" />
                      Edit
                    </div>
                    <div
                      className="delete-btn"
                      onClick={() => handleDeleteFaq(faqs?._id)}
                    >
                      <img src={DeleteIcon} alt="delete-icon" />
                      Delete
                    </div>
                  </div>
                  <div className="faqtitle-description-container">
                    <div className="faq-title-text">{faqs?.title || "N/A"}</div>
                    <div className="faq-desc-text">
                      {faqs?.content || "N/A"}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ textAlign: "center" }}>No FAQ Found</div>
            )}
          </>
        </div>
      )}
    </>
  );
};

export default FAQ;