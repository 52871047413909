import React, { useState } from 'react'
import Card2 from '../../common/card2/Card2'
import './ContentManagement.css';
import { Link, useNavigate } from 'react-router-dom';

 const ContentManagement = () => {

  const navigate = useNavigate();
  
  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Content Management</span>,
  ];


  return (
    <div>
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>


      <Card2 
        onClick={()=> navigate('/dashboard/content-management/faq')} 
        >
        <div className='push-notification-text'>
          <div>FAQ</div>
        </div>
      </Card2>
    </div>
  )
}

export default ContentManagement