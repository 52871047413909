import React from 'react'
import { Link } from 'react-router-dom';
import TableCard from '../../common/tableCard/TableCard';
import SearchBar from '../../common/searchbar/SearchBar';
import TableComp from '../../common/tablecomp/TableComp';
import ReviewsRow from '../../common/tablerow/ReviewsRow';
import Card1 from '../../common/card1/Card1';

const ChefReviews = () => {
  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };
  const titles = [
    { title: "Sr No" },
    { title: "Name" },
    { title: "Event Name" },
    { title: "Reviews" },
    { title: "Rating" },
    { title: "Date" },

  ];
  const rows = [
    {

      id: 1,
      email: "Review By: John Doe",
      experience: "13th Street. 47 W 13th St, New York, NY 10011, USA.  ",
      events: "1",

      earnings: "05/16/2022",
      joineddate: "Lorem ipsum dolor sit amet consectetur. ",

    },
    {

      id: 2,
      email: "Review By: John Doe",
      experience: "13th Street. 47 W 13th St, New York, NY 10011, USA.  ",
      events: "1",

      earnings: "05/16/2022",
      joineddate: "Lorem ipsum dolor sit amet consectetur. ",

    },

  ];

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/chefs/all-chefs"
      className="All-color-breakcurm"
    >
      Chef’s Details
    </Link>,
    <Link
      key="home"
      to="/dashboard/chefs/all-chefs/chefs-information"
      className="All-color-breakcurm"
    >
      Chef’s Name
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Reviews</span>,
  ];
  return (
    <div>

      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>



      <div className="chef-details-card-container">
        <Card1 value="4.4"
          value1="124 reviews"
          text="Total Reviews  >"
          style={style} />

      </div>

    <div className='chef-detail-tble-wrapper'>
    <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
             gap:'10px'
          }}
        >
          <div>Reviews Details</div>
          <div>
            <SearchBar />
          </div>
        </div>

        <TableComp title={titles} emptyEColumnCount={1}>
          {rows.map((item) => (
            <ReviewsRow row={item} />
          ))}
        </TableComp>
      </TableCard>
    </div>
    </div>
  )
}

export default ChefReviews