import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomBtn from '../../common/custombtn/CustomBtn';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import EyeClose from '../../common/assest/ion_eye-off.svg';
import EyeOpen from '../../common/assest/opnEye.svg';

import './forgot.css';
import { changePasswordApi } from '../../api';
import { toast } from 'react-toastify';
import Loader from '../../common/loader/Loader';

const ConfirmPass = ({otpData}) => {
    const navigate = useNavigate();
    const [newPass, setNewPass] = useState("");
    const [confirmNewPass, setConfirmNewPass] = useState("");
    const [loading,setLoading]=useState(false)

    // State to manage the visibility of passwords
    const [passwordVisibility, setPasswordVisibility] = useState({
        showPassword: false,
        showConfirmPassword: false,
    });

    // Function to toggle password visibility
    const togglePasswordVisibility = (field) => {
        setPasswordVisibility((prev) => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const handleConfirmPassSubmit =async () => {
        if (!newPass || !confirmNewPass) {
            toast.error("All fields are required");
            return;
          }
          if (newPass !== confirmNewPass) {
            toast.error("Passwords did not match");
            return;
          }
          if (newPass.length < 8) {
            toast.error("Password should be at least 8 characters long");
            return;
          }
      try {
        setLoading(true)
        const response=await changePasswordApi({otpData,confirmNewPass})
        const successMsg = response?.message;
        if (Array.isArray(successMsg)) {
          successMsg.forEach((msg) => toast.success(msg));
        } else if (successMsg) {
          toast.success(successMsg);
        }
        setLoading(false)
        setNewPass("")
        setConfirmNewPass("")
        navigate("/")
      } catch (error) {
        console.log("error",error);
        setLoading(false)
        const errorMessage = error?.data?.error;
        if (Array.isArray(errorMessage)) {
          errorMessage.forEach((msg) => toast.error(msg));
        } else if (errorMessage) {
          toast.error(errorMessage);
        }
      }
      
    };

    return (
        <>
       {loading ? (
<Loader/>
       ):(
        <div>
         
        <div className='fogot-container'>
            <div className='fogot-pass-txt'>
                <span style={{ color: "#EA1D26" }}>Create</span> New Password
            </div>
            <Box sx={{ marginTop: "15px", width: '100%', padding: '20px' }}>
                <Typography
                    sx={{
                        marginBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "500",
                        textIndent: "10px",
                        fontFamily: "Rosario",
                    }}
                >
                    Create New Password
                </Typography>
                <TextField
                    type={passwordVisibility.showPassword ? "text" : "password"}
                    placeholder="Create New Password"
                    name="password"
                    onChange={(e) => 
                        {
                          if (e.target.value.trim() !== "" || e.target.value === "") {
                            setNewPass(e.target.value)}
                          }
                        }
                    sx={{
                        width: "100%",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <img
                                    src={passwordVisibility.showPassword ? EyeOpen : EyeClose}
                                    alt="toggle-password-visibility"
                                    className='eyeicon'
                                    style={{ width: "20px", cursor: "pointer" }}
                                    onClick={() => togglePasswordVisibility('showPassword')}
                                />
                            </InputAdornment>
                        ),
                        style: { borderRadius: "28px", backgroundColor: "#F0F0F0", fontSize: '14px', fontFamily: 'Rosario' },
                    }}
                />

                <Typography
                    sx={{
                        marginTop: "20px",
                        marginBottom: "8px",
                        fontSize: "16px",
                        fontWeight: "500",
                        textIndent: "10px",
                        fontFamily: "Rosario",
                    }}
                >
                    Confirm New Password
                </Typography>
                <TextField
                    type={passwordVisibility.showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    name="confirmPassword"
                    onChange={(e) => 
                        {
                          if (e.target.value.trim() !== "" || e.target.value === "") {
                            setConfirmNewPass(e.target.value)}
                          }
                        }
                    sx={{
                        width: "100%",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <img
                                    src={passwordVisibility.showConfirmPassword ? EyeOpen : EyeClose}
                                    alt="toggle-password-visibility"
                                    className='eyeicon'
                                    style={{ width: "20px", cursor: "pointer" }}
                                    onClick={() => togglePasswordVisibility('showConfirmPassword')}
                                />
                            </InputAdornment>
                        ),
                        style: { borderRadius: "28px", backgroundColor: "#F0F0F0", fontSize: '14px', fontFamily: 'Rosario' },
                    }}
                />
            </Box>
            <CustomBtn text="SUBMIT" onClick={handleConfirmPassSubmit} />
        </div>
    </div>

       )} 
       
        
        </>
    );
};

export default ConfirmPass;
