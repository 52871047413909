import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import Card1 from "../../common/card1/Card1";
import "./ChefDetails.css";
import TableCard from "../../common/tableCard/TableCard";
import ChefRow from "../../common/tablerow/ChefRow";
import TableComp from "../../common/tablecomp/TableComp";
import SearchBar from "../../common/searchbar/SearchBar";
import userimg from "../../common/assest/usrimg.png";
import CustomModal from "../../common/modal/CustomModal";
import ClearIcon from "@mui/icons-material/Clear";
import SearchWithFilter from "../../common/searchbar/SearchWithFilter";
import { getChefsCountApi, getChefsCountInMonthApi } from "../../api";
import { useSelector } from "react-redux";
import Loader from "../../common/loader/Loader";


const ChefDetails = () => {

  const token = useSelector((state) => state?.user?.user?.token);
  const [loading,setLoading]=useState(false);
  const [totalCount,setTotalCount]=useState(0);
  const [countInMonth,setCountInMonth]=useState(0);
  const [open, setOpen] = useState(false);


  

  const handleCounts = async () => {
    try {
      setLoading(true)
      const [responseCount, responseCountInMonth] = await Promise.all([
        getChefsCountApi({ token }),
        getChefsCountInMonthApi({ token })
      ]);
      setLoading(false)
      setTotalCount(responseCount || 0); 
      setCountInMonth(responseCountInMonth|| 0);
    } catch (error) {
      setLoading(false)
      console.log("Error:", error);
    }
  };
  
  useEffect(() => {
    handleCounts();
  }, []);
  


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openModal = (item) => {
    setOpen(true);
  };

  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,

    <span style={{ fontWeight: "bolder" }}>&nbsp;Chef’s Details</span>,
  ];

  const style = {
    minHeight: "95px",
    paddingTop: "22px",
    cursor: "default",
  };

  const titles = [
    { title: "Chef Name" },
    { title: "Email" },
    { title: "Experience" },
    { title: "No of Events" },
    { title: "Ratings" },
    { title: "Earnings" },
    { title: "Joined Date" },
    { title: "Subscription " },
    { title: "Account Status " },
  ];
  const rows = [
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      email: "joe@gmail.com",
      experience: "2",
      events: "150",
      rating: "4.4",
      earnings: "204",
      joineddate: "05/16/2022",
      subs: "free",
      accountstatus: "Active",
    },
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      email: "joe@gmail.com",
      experience: "2",
      events: "150",
      rating: "4.4",
      earnings: "204",
      joineddate: "05/16/2022",
      subs: "free",
      accountstatus: "Active",
    },
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      email: "joe@gmail.com",
      experience: "2",
      events: "150",
      rating: "4.4",
      earnings: "204",
      joineddate: "05/16/2022",
      subs: "free",
      accountstatus: "Active",
    },
  ];

  return (
    <>
    
    
    {loading ?(
      
      <Loader/>
    ):(
        
    <div>
     
      <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>

      <div className="chef-details-card-container">
        <Card1 value={totalCount} text="Total Chefs" style={style} />
        <Card1 value={countInMonth} text="Total Users This Month" style={style} />
        <Card1
          value="67890"
          text="Active Users Currently on App"
          style={style}
        />
      </div>

     <div className="chef-detail-tble-wrapper">
     <TableCard>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "20px",
            fontFamily: "Rosario",
            fontWeight: 600,
            fontSize: "20px",
            gap:'10px'
          }}
        >
          <div>Chef’s Details</div>
          <div>
            <SearchWithFilter />
          </div>
        </div>

        <TableComp title={titles} emptyEColumnCount={2}>
          {rows.map((item) => (
            <ChefRow handleViewMore={() => openModal(item)} row={item} />
          ))}
        </TableComp>
      </TableCard>
     </div>

      <CustomModal open={open} handleClose={handleClose}>
        <div
          onClick={handleClose}
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            color: "#6E6E6E",
            cursor: "pointer",
          }}
        >
          <ClearIcon />
        </div>

        <div className="img-mail-name-container">
            <div><img src={userimg}/></div>
            <div className="name-email-container">
                <div  style={{fontFamily:'Rosario'}}>Jonathan Williams</div>
                <div style={{color:'#868686',fontFamily:'Rosario'}}>joe@gmail.com</div>
            </div>
          </div>
        <div className="modal-txt">Address:<span> 13th Street. 47 W 13th St, New York, NY 10011, USA. </span> </div>
        <div className="modal-txt">Birthdate:<span> 13th November, 1998</span> </div>
        <div className="modal-txt">Specialty:<span> Italian, Indian </span> </div>
        <div className="modal-txt">Cooking Style:<span> Lorem Ipsum Dolar Est </span> </div>
        <div className="modal-txt">Language:<span> English, Spanish, Hindi </span> </div>
        <div className="modal-txt">Food Handler Section:<span> Lorem Ipsum Dolar Est</span> </div>
        <div className="modal-txt">Bank Account Details: 
          <p>Bank Name:<span> 47 W 13th St, New York, NY 10011, USA.</span> </p> 
          <p>Account Holders Name: <span> 47 W 13th St, New York, NY 10011, USA.</span> </p> 
          <p>Bank Code:<span> 47 W 13th St, New York, NY 10011, USA.</span> </p> 
          <p>Account Number:<span> 47 W 13th St, New York, NY 10011, USA.</span> </p> 
      </div>
      <div className="modal-txt">Portfolio: </div>
      </CustomModal>

      <div className="txt-sample">
        Copyright © 2024 Chef’s Table All rights reserved.
      </div>
    </div>
     )}
    </>
  );
};

export default ChefDetails;
