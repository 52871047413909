import React, { useState } from "react";
import CustomCard from "../../common/customcard/CustomCard";
import LeftImg from "../../common/assest/leftimg.png";
import RightImg from "../../common/assest/rightimg.png";
import OTPVerify from "./OTPVerify";
import ConfirmPass from "./ConfirmPass";
import "./forgot.css";
import { Box, TextField, Typography } from "@mui/material";
import CustomBtn from "../../common/custombtn/CustomBtn";
import { verifyEmailApi } from "../../api";
import Loader from "../../common/loader/Loader";
import { toast } from "react-toastify";

const EmailVerify = () => {

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [otpData, setOtpData] = useState(null); 

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleVerifyEmail = async () => {
    if (!email) {
      toast.warning("Email required.");
      return;
    }
    if (!validateEmail(email)) {
      toast.error("Please provide a valid email address.");
      return;
    }
    try {
      setLoading(true);
      const response = await verifyEmailApi({email});
      console.log("emaik", response,response.data.reference);
      setOtpData({
        reference:response?.data?.reference,
        otp:response?.data?.otp
      })
      setLoading(false);
      const successMsg = response?.message;
      if (Array.isArray(successMsg)) {
        successMsg.forEach((msg) => toast.success(msg));
      } else if (successMsg) {
        toast.success(successMsg);
      }
      setEmail("")
      handleNextStep();
    } catch (error) {
      setLoading(false);
      const errorMessage = error?.data?.error;
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((msg) => toast.error(msg));
      } else if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  return (
    <div className="signup-container">
      {loading && <Loader />}
      <div className="signup-grid">
        <div className="left-image">
          <img src={LeftImg} alt="Left" />
        </div>

        <div className="right-image">
          <img src={RightImg} alt="Right" className="r1" />
        </div>

        <CustomCard>
          {step === 1 && (
            <div className="fogot-container">
              <div className="fogot-pass-txt">
                <span style={{ color: "#EA1D26" }}>Forgot</span> Your Password?
              </div>
              <Box sx={{ marginTop: "15px", width: "100%", padding: "20px" }}>
                <Typography
                  sx={{
                    marginBottom: "8px",
                    fontSize: "17px",
                    fontWeight: "600",
                    textIndent: "10px",
                    fontFamily: "Rosario",
                  }}
                >
                  Registered Email
                </Typography>
                <TextField
                  className="email-txt-field"
                  type="email"
                  placeholder="Enter your Email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    if (e.target.value.trim() !== "" || e.target.value === "") {
                      setEmail(e.target.value);
                    }
                  }}
                  sx={{
                    width: "100%",
                    border: "none",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                    },
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "28px",
                      backgroundColor: "#F0F0F0",
                      border: "none",
                      fontSize: "14px",
                      fontFamily: "Rosario",
                    },
                  }}
                />
              </Box>
              <CustomBtn text="SUBMIT" onClick={handleVerifyEmail} />
            </div>
          )}

          {step === 2 && <OTPVerify onNext={handleNextStep} otpData={otpData}/>}

          {step === 3 && <ConfirmPass otpData={otpData} />}
        </CustomCard>
      </div>
    </div>
  );
};

export default EmailVerify;
