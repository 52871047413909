
import { TextField, InputAdornment } from '@mui/material'
import SearchItem from '../../common/assest/searchIcon.svg'
const SearchBar = ({style}) => {
  return (
      <TextField
          
           placeholder="Search..."
           sx={{
             width: {  sm: 250 }, 
             ...style,
             height: {xs:45,sm:45},
             flexShrink: 0,
             borderRadius: "33px",
             background: "#F6F5F6",
             
             border: "none", 
             "& .MuiOutlinedInput-root": {
               borderRadius: "33px",
               height: "100%",
               paddingRight: "10px",
               outline: "none",
               border: "none", 
             },
             "& .MuiOutlinedInput-notchedOutline": {
               border: "none", 
             },
           }}
           InputProps={{
             startAdornment: (
               <InputAdornment position="start">
                  <img src={SearchItem}
                   sx={{ color: "#000", opacity: "0.35" }}
                 />
               </InputAdornment>
             ),
             style: { height: "100%" },
           }}
         />
  )
}

export default SearchBar