import React, { useState } from 'react';
import { Button } from '@mui/material';
import FoodImg from '../assest/food.png';
import TickIcon from '../assest/tickIcon.svg'
import CrossIcon from '../assest/crossIcon.svg'
import MessageIcon from '../assest/messageIcon.svg'
import Photos1 from '../assest/photos2.png'
import Photos2 from '../assest/photos1.png'
import './EventDetailsbtn.css';




const EventDetailsbtn = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [menuActiveTab, setMenuActiveTab] = useState(0);

  const handleClick = (index) => {
    setActiveTab(index);
  };

  const starter = [
    {
      name: "Caesar Salad",
      description: "Crisp romaine lettuce with Caesar dressing and croutons.",
      price: "$12.99",
      imageUrl: FoodImg, 
    },
    {
      name: "Garlic Butter Shrimp",
      description: "Succulent shrimp sautéed in garlic butter and herbs.",
      price: "$14.99",
      imageUrl: FoodImg,
    },
    {
      name: "Stuffed Mushrooms",
      description: "Mushrooms filled with creamy cheese and herbs.",
      price: "$10.99",
      imageUrl: FoodImg,
    },
    {
      name: "Bruschetta",
      description: "Toasted bread topped with fresh tomatoes, basil, and balsamic glaze.",
      price: "$8.99",
      imageUrl: FoodImg,
    },
    {
      name: "Vegetable Spring Rolls",
      description: "Crispy spring rolls filled with seasoned vegetables.",
      price: "$7.99",
      imageUrl: FoodImg,
    },
    {
      name: "Cheese Platter",
      description: "A selection of gourmet cheeses served with crackers.",
      price: "$15.99",
      imageUrl: FoodImg,
    },
  ];
  const guestListData = [
    {
      name: "John Deo",
      totalGuests: 3,
      bookingId: "7865435678",
    },
    {
      name: "Jane Smith",
      totalGuests: 2,
      bookingId: "1234567890",
    },
    {
      name: "Alice Johnson",
      totalGuests: 4,
      bookingId: "9876543210",
    },
    {
      name: "Bob Brown",
      totalGuests: 5,
      bookingId: "1122334455",
    },
    {
      name: "Bob Brown",
      totalGuests: 5,
      bookingId: "1122334455",
    },
    
  ];
  const images = [
  { src: Photos2, alt: 'Image 1' },
  { src: Photos1, alt: 'Image 2' },
  { src: Photos2, alt: 'Image 3' },
  { src: Photos1, alt: 'Image 4' },
  { src: Photos2, alt: 'Image 5' },
  { src: Photos1, alt: 'Image 6' },
 
];

  return (
    <div>
      {/* Render buttons */}
      <div className='five-btn-container'>
  <Button
    onClick={() => handleClick(0)}
    className="responsive-btn"
    sx={{
      width: '168px',
      height: '65px',
      borderRadius: '100px',
      textTransform: 'none',
      border: '1px solid #DCDCDC',
      backgroundColor: activeTab === 0 ? '#EA1D26' : '#FFF',
      color: activeTab === 0 ? '#FFF' : '#C6C6C6',
      '&:hover': { backgroundColor: '#EA1D26' },
    }}
  >
    About Listing
  </Button>
  <Button
    onClick={() => handleClick(1)}
    className="responsive-btn"
    sx={{
      width: '168px',
      height: '65px',
      border: '1px solid #DCDCDC',
      borderRadius: '100px',
      textTransform: 'none',
      backgroundColor: activeTab === 1 ? '#EA1D26' : '#FFF',
      color: activeTab === 1 ? '#FFF' : '#C6C6C6',
      '&:hover': { backgroundColor: '#EA1D26' },
    }}
  >
    Menu
  </Button>
  <Button
    onClick={() => handleClick(2)}
    className="responsive-btn"
    sx={{
      width: '168px',
      height: '65px',
      border: '1px solid #DCDCDC',
      borderRadius: '100px',
      textTransform: 'none',
      backgroundColor: activeTab === 2 ? '#EA1D26' : '#FFF',
      color: activeTab === 2 ? '#FFF' : '#C6C6C6',
      '&:hover': { backgroundColor: '#EA1D26' },
    }}
  >
    Rules
  </Button>
  <Button
    onClick={() => handleClick(3)}
    className="responsive-btn"
    sx={{
      width: '168px',
      height: '65px',
      border: '1px solid #DCDCDC',
      borderRadius: '100px',
      textTransform: 'none',
      backgroundColor: activeTab === 3 ? '#EA1D26' : '#FFF',
      color: activeTab === 3 ? '#FFF' : '#C6C6C6',
      '&:hover': { backgroundColor: '#EA1D26' },
    }}
  >
    Guest Lists
  </Button>
  <Button
    onClick={() => handleClick(4)}
    className="responsive-btn"
    sx={{
      width: '168px',
      height: '65px',
      border: '1px solid #DCDCDC',
      borderRadius: '100px',
      textTransform: 'none',
      backgroundColor: activeTab === 4 ? '#EA1D26' : '#FFF',
      color: activeTab === 4 ? '#FFF' : '#C6C6C6',
      '&:hover': { backgroundColor: '#EA1D26' },
    }}
  >
    Photos
  </Button>
</div>


      {/* Tab content */}
      <div style={{ marginTop: '25px',padding:'10px' }} className='tab-wrappers-data'>

          {/*about listing*/}
        {activeTab === 0 && (
          <>
            <div className="section-header">Name of Your Place</div>
            <p className="section-description">Eden Garden Crescent</p>

            <div className="section-header">Dining Style</div>
            <p className="section-description">Outdoorsy</p>

            <div className="section-header">Describe your place to guests</div>
            <p className="section-description">
              Lorem ipsum dolor sit amet consectetur. Gravida vehicula varius ornare ac nec velit gravida at.
            </p>

            <div className="section-header">Available Seats</div>
            <p className="section-description">4</p>

            <div className="section-header">Street Address</div>
            <p className="section-description">10130 Perimeter Parkway Suite</p>

            <div className="section-header">City</div>
            <p className="section-description">Charlotte</p>

            <div className="section-header">State</div>
            <p className="section-description">North Carolina</p>

            <div className="section-header">Zip</div>
            <p className="section-description">1087</p>
          </>
        )}


 {/*menu*/}

        {activeTab === 1 && (
          <>
            <div className="course-tabs">
              <span onClick={() => setMenuActiveTab(0)} style={{color:menuActiveTab===0 ? "#EA1D26":"#000"}}>Starter</span>
              <span onClick={() => setMenuActiveTab(1)}  style={{color:menuActiveTab===1 ? "#EA1D26":"#000"}} >Main Course</span>
              <span onClick={() => setMenuActiveTab(2)}  style={{color:menuActiveTab===2 ? "#EA1D26":"#000"}} >Dessert</span>
            </div>

            <div className="menu-card-container" style={{ marginTop: '25px' }}>
              {menuActiveTab === 0 && (
                <>
                {starter.map((item)=>(

                  <div className="guest-menu--card">

                    <img src={item.imageUrl} alt="Card Image" className="guest-menu--card-image" />
                    <div className="guest-menu--card-content">
                      <span className="guest-menu--card-name">{item.name}</span>
                      <span className="guest-menu--card-description">{item.description}</span>
                      <span className="guest-menu--card-price">{item.price}</span>
                    </div>
                  </div>
                ))}
                </>
              )}

              {menuActiveTab === 1 && (
                <>
                 {starter.map((item)=>(

<div className="guest-menu--card">

  <img src={item.imageUrl} alt="Card Image" className="guest-menu--card-image" />
  <div className="guest-menu--card-content">
    <span className="guest-menu--card-name">{item.name}</span>
    <span className="guest-menu--card-description">{item.description}</span>
    <span className="guest-menu--card-price">{item.price}</span>
  </div>
</div>
))}
                </>
              )}

              {menuActiveTab === 2 && (
                <>
                  <div className="guest-menu--card">
                    <img src={FoodImg} alt="Card Image" className="guest-menu--card-image" />
                    <div className="guest-menu--card-content">
                      <span className="guest-menu--card-name">Cheesecake</span>
                      <span className="guest-menu--card-description">Creamy cheesecake with strawberries.</span>
                      <span className="guest-menu--card-price">$7.99</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}



 {/*rules*/}
        {activeTab === 2 && 
        
        <>
        <div className='rules'>
          <div>Suitable for children aged below 18</div>
          <div><img src={TickIcon} alt='icon'/></div>
       </div>
       <div className='rules'>
          <div>Suitable for pets</div>
          <div><img src={CrossIcon} alt='icon'/></div>
       </div>
       <div className='rules'>
          <div>Smoking Area</div>
          <div><img src={CrossIcon} alt='icon'/></div>
       </div>
       <div className='rules'>
          <div>Alcohol Served</div>
          <div><img src={TickIcon} alt='icon'/></div>
       </div>

       <div className='rules'>
       How much notice do you need before a guest arrives
        </div>
        <span className='rules-txt'>30</span>
        <div className='rules'>
        How much far in advance the guest can book?
        </div>
        <span className='rules-txt'>30</span>
        <div className='rules'>
        Manage Availability
        </div>
        <span className='rules-txt'>09-18-2023 To 07-18-2023</span>


       </>
        
        }

{/* guests */}
        {activeTab === 3 && 
        
         <div className="guest-list-container">
          {guestListData.map((guest, index) => (
            <div className="guest-list-card" key={index}>
              <div className="guest-list-content">
                <div className="guest-details">
                  <span className="guest-name">{guest.name}</span>
                  <span className="guest-total">{guest.totalGuests} Total Guests</span>
                  <span className="guest-id">Booking ID: {guest.bookingId}</span>
                </div>
                <div className="guest-image">
                  <img src={MessageIcon} alt="icon" />
                </div>
              </div>
            </div>
          ))}
        </div>
          
          }

{/* imges */}
         {activeTab === 4 && (
        <div className="image-grid-container">
          {images.map((image, index) => (
            <div className="image-grid-item" key={index}>
              <img src={Photos1} alt="img" className="image-grid-img" />
            </div>
          ))}
        </div>
      )}
      </div>
    </div>
  );
};

export default EventDetailsbtn;
