import React from 'react';
import { Link } from 'react-router-dom';
import TableCard from '../../common/tableCard/TableCard';
import SearchBar from '../../common/searchbar/SearchBar';
import TableComp from '../../common/tablecomp/TableComp';
import ReportRow from '../../common/tablerow/ReportRow';
import userimg from "../../common/assest/usrimg.png";

const ChefReport = () => {
  const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
     Chef’s 
  </Link>,
    
    <span style={{ fontWeight: "bolder" }}>&nbsp;Reports</span>,
  ];

  const titles = [
    { title: "Chef Name" },
    { title: "Email" },
    { title: "Date" },
    { title: "Report Type" },
   
  ];
  const rows = [
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      email: "joe@gmail.com",
      earnings: "Lorem ipsum",
      joineddate: "05/16/2022",
     
    },
    {
      chefImage: userimg,
      chefName: "Jonathan Williams",
      email: "joe@gmail.com",
      earnings: "Lorem ipsum",
      joineddate: "05/16/2022",
    
    },
    
  ];


  const styles={
    width:"275px"
  }
  return (
    <div>
         <div className="bread-crum">
            {breadcrumbItems.map((item, index) => (
              <div className="all-breakcrum" key={index}>
                {item}
                {index < breadcrumbItems.length - 1 && " > "}
              </div>
            ))}
          </div>

          <div className='whole-card-wrapper-notification'>
          <TableCard>
          
            <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',padding:'15px',gap:"10px"}}>
              <div className='push-notification-txt'>Chef’s Reports</div>
              <div><SearchBar style={styles}/></div>
            </div>

            <TableComp title={titles} emptyEColumnCount={2}>
            {rows.map((item) => (
                <ReportRow row={item}/>
              ))}
          </TableComp>


          </TableCard>
          </div>


          
    </div>
  )
}

export default ChefReport