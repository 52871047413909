import React from 'react';
import './Card2.css';

const Card2 = ({ children, onClick, style, }) => {
  return (
    <div className='card2-container' onClick={onClick} >
      {children}
    </div>
  );
}

export default Card2;
