import React, { useState } from "react";
import { TableRow, TableCell, Box, Avatar, Menu, MenuItem } from "@mui/material";
import GradeIcon from '@mui/icons-material/Grade';
import CharMenuIcon from '../assest/charmenuicon.svg'
const ReviewsRow = ({ row }) => {


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>

            <TableRow >
                <TableCell>
                    <Box

                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            // flexWrap: "wrap",
                            gap: "10px",
                            cursor: "pointer"
                        }}
                    >

                        <div style={{

                            fontFamily: "Rosario",

                            fontWeight: 500,
                            fontSize: "17px",
                        }}>
                            {row.id || "-"}
                        </div>
                    </Box>
                </TableCell>

                <TableCell

                    sx={{

                        fontFamily: "Rosario",

                        fontSize: "17px",
                    }}
                >
                    <div >{row.email}</div>
                </TableCell>

                <TableCell
                    sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
                >
                    <div>{row.experience}</div>
                </TableCell>


                <TableCell
                    sx={{
                        fontFamily: "Rosario",
                        fontWeight: 500,
                        fontSize: "17px",

                    }}
                >
                    <div>{row.joineddate}</div>
                </TableCell>


                <TableCell
                    sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px" }}
                >
                    <div>{row.events}</div>
                </TableCell>


                <TableCell
                    sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px", color: '#FFC107' }}
                >
                    <div><GradeIcon /></div>
                </TableCell>


                <TableCell

                    sx={{ fontFamily: "Rosario", fontWeight: 500, fontSize: "17px", cursor: 'pointer' }}
                >
                    <div><img src={CharMenuIcon} alt="icon" onClick={handleClick} /></div>
                </TableCell>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '25px',
                            bgcolor: 'white',
                            boxShadow: "none",
                            border: "1px solid #B0B0B0",
                            padding: '5px',
                            width: "140px",

                        },
                        '& .MuiMenuItem-root': {
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            '&.Mui-focusVisible': {
                                backgroundColor: 'transparent',
                            },
                            '&:focus': {
                                backgroundColor: 'transparent',
                            },
                        },
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItem onClick={handleClose} sx={{ fontFamily: 'Rosario', color: '#EA1D26', fontWeight: '400' }}>Delete Account</MenuItem>
                </Menu>





            </TableRow>



        </>
    );
};

export default ReviewsRow;
