import React from 'react';
import { Link } from 'react-router-dom';
import QuillEditor from '../../common/ReactQuil/Mission';

const ChefPrivacy = () => {

    const breadcrumbItems = [
    <Link key="home" to="/dashboard" className="All-color-breakcurm">
      Home
    </Link>,
    <Link key="home" to="/dashboard/chefs" className="All-color-breakcurm">
      Chef’s
    </Link>,
    <Link
      key="home"
      to="/dashboard/chefs/content-management"
      className="All-color-breakcurm"
    >
      Content
    </Link>,
    <span style={{ fontWeight: "bolder" }}>&nbsp;Privacy Policy</span>,
  ];

  return (
    <div>
          <div className="bread-crum">
        {breadcrumbItems.map((item, index) => (
          <div className="all-breakcrum" key={index}>
            {item}
            {index < breadcrumbItems.length - 1 && " > "}
          </div>
        ))}
      </div>
      <div >
            <QuillEditor/>
        </div>
    </div>
  )
}

export default ChefPrivacy