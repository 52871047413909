import React from 'react';
import { Card } from '@mui/material';

const TableCard = ({children}) => {
  return (
    <Card
      sx={{
        width: 'auto',
        height: 'auto',
        flexShrink: 0,
        borderRadius: '25px',
        backgroundColor: '#FFF',
        boxShadow: '2px 4px 40px 0px rgba(0, 0, 0, 0.04)',
        marginTop:"-40px",
        '@media (max-width:1024px)': {
           marginTop:'40px',
           padding:'15px'
          },
        // '@media (max-width:800px)': {
        //    marginTop:'40px',
        //    padding:'15px'
        //   },
      }}
    >
      {children}
    </Card>
  );
};

export default TableCard;
